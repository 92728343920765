import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';
import { DataService } from 'src/app/services/data.service'
declare let $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit, AfterViewInit {

  loading;
  users; chatSelected;
  currentUser; userid; type;
  submitted; msgwrite = { id: '', message: '', userid: '1' };
  chat = [];
  // [
  //   {
  //     id: '1',
  //     userid:'1',
  //     message: 'msg1'
  //   },
  //   {
  //     id: '2',
  //     userid:'Atr9DVuy1uQbvk7a3rE4FE4lmz43',
  //     message: 'msg2'
  //   },
  //   {
  //     id: '3',
  //     userid:'Atr9DVuy1uQbvk7a3rE4FE4lmz43',
  //     message: 'msg3'
  //   },
  //   {
  //     id: '4',
  //     userid:'1',
  //     message: 'msg4'
  //   }f
  // ]

  constructor(
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService) { }

  ngOnInit(): void {
    let self = this;

    this.route.params.subscribe(params => {
      let self = this;

      if (params['userid'] && params['type']) {
        this.userid = params['userid'];
        this.type = params['type'];

        // self.chat = data1.filter(c => {
        //   return (c['ownerid'] == self.currentUser['uid'] && c['userid'] == self.userid) ||
        //     (c['ownerid'] == self.userid && c['userid'] == self.currentUser['uid']);
        // })

        this.afAuth.authState.subscribe((data) => {
          this.currentUser = data;

          this.dataService.getList("chat").subscribe((data) => {

            this.users = [];
            if (data){
              data.forEach(element => {
                if (element['id'].toString().toLowerCase().indexOf(this.currentUser['uid'].substr(0, 10).toLowerCase()) >= 0)
                this.users.push(element);
              });

              this.dataService.getList("users").subscribe((datau) => {

                if (datau){
                  this.users.forEach(element1 => {
                    datau.forEach(element2 => {

                        if (element1['ownerid'] != this.currentUser['uid'] && element1['ownerid'] == element2['uid']){
                          element1['nameid'] = element2['uid'];
                          element1['name'] = element2['profile']['nume'];
                        }
                        else if (element1['userid'] != this.currentUser['uid'] && element1['userid'] == element2['uid']){
                          element1['nameid'] = element2['uid'];
                          element1['name'] = element2['profile']['nume'];
                        }

                        if (element2['uid'] == this.userid)
                        this.chatSelected = {name: element2['profile']['nume']};
                    });
                  });
                }
              })
            }
          })

          let id1 = this.currentUser['uid'].substr(0, 10) + this.userid.substr(0, 10);
          let id2 = this.userid.substr(0, 10) + this.currentUser['uid'].substr(0, 10);

          this.dataService.getItemByID("chat", id1).subscribe((data1) => {
            if (data1){
              this.dataService.saveItem("chat", { id: id1, new: this.type != 1?true:false }).then((data) => {
              this.dataService.getSubList("chat", id1, "talks").subscribe((data1x) => {
                self.chat = data1x;
              })
            })
            }
            else{
              this.dataService.getItemByID("chat", id2).subscribe((data2) => {
                if (data2){
                  this.dataService.saveItem("chat", { id: id2, new: this.type != 1?true:false }).then((data) => {
                  this.dataService.getSubList("chat", id2, "talks").subscribe((data2x) => {
                    self.chat = data2x;
                  })
                })
                }
                else{
    
                }
              })
            }
          })

        });

      }

    });

  }

  ngAfterViewInit() {
    $('.headerindex').css('display', 'none');
    $('.footerindex').css('display', 'none');
  }

  addMessage() {

    let self = this;
    this.submitted = true;

    if (!this.userid)
      return;

    if (!this.msgwrite['message'])
      return;

    let add;
    this.msgwrite['date'] = new Date();
    this.msgwrite['ownerid'] = this.currentUser['uid'];
    this.msgwrite['userid'] = this.userid;

    let id1 = this.msgwrite['ownerid'].substr(0, 10) + this.msgwrite['userid'].substr(0, 10);
    let id2 = this.msgwrite['userid'].substr(0, 10) + this.msgwrite['ownerid'].substr(0, 10);

    let sub1 = this.dataService.getItemByID("chat", id1).subscribe((data1) => {
      sub1.unsubscribe();

      if (data1) {
        add = true;
        this.dataService.saveItem("chat", { id: id1, new: this.type != 1?true:false }).then((data) => {
          this.dataService.saveSubItem("chat", id1, "talks", this.msgwrite).then((data) => {
            this.submitted = false;
            this.msgwrite = { id: '', message: '', userid: '1' };
          })
        })
      }
      else {
        let sub2 = this.dataService.getItemByID("chat", id2).subscribe((data2) => {
          sub2.unsubscribe();

          if (data2) {
            add = true;
            this.dataService.saveItem("chat", { id: id2, new: this.type != 1?true:false }).then((data) => {
              this.dataService.saveSubItem("chat", id2, "talks", this.msgwrite).then((data) => {
                this.submitted = false;
                this.msgwrite = { id: '', message: '', userid: '1' };
              })
            })
          }
          else {
            this.dataService.saveItem("chat", { id: id1, ownerid: this.currentUser['uid'],  userid: this.userid, new: this.type != 1?true:false }).then((data) => {
              this.dataService.saveSubItem("chat", id1, "talks", this.msgwrite).then((data) => {
                this.submitted = false;
                this.msgwrite = { id: '', message: '', userid: '1' };
              })
            })
          }
        });
      }
    });


  }

  deleteMessage(data) {
    let self = this;

    if (!confirm('Delete?'))
      return;

    for (let i = 0; i < this.chat.length; i++) {
      if (this.chat[i]['id'] == data['id']) {
        this.chat.splice(i, 1);
      }
    }

    this.dataService.deleteItem("chat", data['id']).then((data) => {
    })
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

  setchat(user){
    let self = this;
    this.chatSelected = user;

    self.chat = [];
    self.loading = true;

    this.userid = this.chatSelected['nameid'];
    let id1 = this.currentUser['uid'].substr(0, 10) + this.userid.substr(0, 10);
    let id2 = this.userid.substr(0, 10) + this.currentUser['uid'].substr(0, 10);

    this.dataService.getItemByID("chat", id1).subscribe((data1) => {
      if (data1){
        this.dataService.saveItem("chat", { id: id1, new: false }).then((data) => {
          this.dataService.getSubList("chat", id1, "talks").subscribe((data1x) => {
            self.chat = data1x;
          })
        })
      }
      else{
        this.dataService.getItemByID("chat", id2).subscribe((data2) => {
          if (data2){
            this.dataService.saveItem("chat", { id: id2, new: false }).then((data) => {
            this.dataService.getSubList("chat", id2, "talks").subscribe((data2x) => {
              self.chat = data2x;
            })
            })
          }
          else{

          }
        })
      }
    })
  }

  listusers(){
    delete this.chatSelected;
  }

}
