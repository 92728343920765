import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service'

declare let $: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit, AfterViewInit {

  list;user;
  currentUser;
  submittedcom;

  loaded;
  msgcopy;genLinkID;

  groups;

  constructor(
    private authService: AuthService, 
    private afAuth: AngularFireAuth, 
    private router: Router,
    private dataService: DataService
    ) { }

  async ngOnInit() {
    let self = this;

    this.afAuth.authState.subscribe(async (data) => {
      this.currentUser = data;

      let sub1 = this.dataService.getItemByID("users", this.currentUser.uid).subscribe((datau) => {
        sub1.unsubscribe();

        this.user = datau;
      })

      await this.dataService.getSubList("users", this.currentUser.uid, "groups").subscribe(async (datax) => {
        this.groups = datax;
        await this.dataService.getList("groups").subscribe(async (datag) => {
          if (data){
            datax.forEach(elementx => {
              datag.forEach(elementg => {
                if (elementx.id == elementg.id){
                  elementx['title'] = elementg['title'];
                  elementx['customlink'] = elementg['customlink'];
                }
              });
            });
          }
        })
      })

    }); 

    this.dataService.getList("users").subscribe((data) => {
      self.list = data;
      self.list = [];
      
      // self.list = [
      //   {
      //     id:'1',
      //     name: 'user1',
      //     img: 'assets/images/noperson.jpg'
      //   },
      //   {
      //     id:'2',
      //     name: 'user2',
      //     img: 'assets/images/noperson.jpg'
      //   },
      //   {
      //     id:'3',
      //     name: 'user3',
      //     img: 'assets/images/noperson.jpg'
      //   }
      // ]

      this.loaded = true;

    })

  }

  ngAfterViewInit() {
    $('.headerindex').css('display', 'none');
    // $('.footerindex').css('display', 'none');
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

  copylink(){
    let self = this;
    var copyText = <HTMLInputElement>document.getElementById("copylink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    this.msgcopy = "Copiat!";
    setTimeout(() => {
      self.msgcopy = '';
    }, 1000);
  }

}
