import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service'
import { CompressImageService } from 'src/app/services/compress-image.service';
declare let $: any;

import { FileUpload } from 'src/app/models/fileupload';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, AfterViewInit {

  @Input() modal: boolean = false;
  @Input() short: boolean = false;
  currentUser;
  submitted;

  profil = {}; user; userevent;
  step_current = 1;

  selectedImgBgFiles;
  public imagePathBg; imgURL: any;
  elemImage; message;
  imagePath; blobImg;
  fieldimg;

  currentFileUpload: FileUpload;
  percentage: number;
  itemImage = {};
  startupload = false;

  loading; loadingadd; loadingbuilder = false;

  loaded;

  constructor(
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private compressImage: CompressImageService,
    private uploadService: UploadFileService) { }

  ngOnInit(): void {

    let self = this;

    this.afAuth.authState.subscribe((data) => {
      this.currentUser = data;

      this.dataService.getItemByID("users", this.currentUser['uid']).subscribe((data) => {

        this.user = data;

        if (data && !data['profile'])
          data['profile'] = {};

          if (this.user['displayName'] && !data['profile']['nume'])
          data['profile']['nume'] = this.user['displayName'];

        self.profil = data['profile'];
        this.loaded = true;
      });

      this.dataService.getItemByID("users_event", this.currentUser['uid']).subscribe((dataevent) => {

        this.userevent = dataevent;
      });

    });

  }

  ngAfterViewInit() {
    $('.headerindex').css('display', 'none');
    // $('.footerindex').css('display', 'none');
  }

  back() {
    if (!this.step_current)
      return;

    if (this.step_current == 1)
      return;

    if (this.step_current == 3) {

    }

    this.step_current = this.step_current - 1;

    this.dataService.saveItem("users", this.user, 'uid').then((data) => {
    });
  }

  next() {

    this.submitted = true;

    if (!this.step_current && this.step_current != 0)
      return;

    //billing details
    if (this.step_current === 1) {
      this.submitted = true;

      if (!this.profil || !this.profil['nume'] || !this.profil['varsta'] || !this.profil['gen'])
        return;
    }

    if (this.step_current === 2) {

    }

    if (this.step_current === 3) {

    }

    if (this.step_current === 4)
      return;

    this.step_current = this.step_current + 1;

    this.dataService.saveItem("users", this.user, 'uid').then((data) => {
    });
  }

  save() {

    this.submitted = true;

    if (!this.profil || !this.profil['nume'] || !this.profil['varsta'] || !this.profil['gen']) {
      this.step_current = 1;
      return;
    }

    this.user['profile'] = this.profil;

    this.dataService.saveItem("users", this.user, 'uid').then((data) => {

      if (this.step_current == 4 && this.modal && document.getElementById('profileclose'))
        document.getElementById('profileclose').click()

      //this.next();

    });

    document.getElementsByClassName('copy-bedge')[0]
      .classList.add('copy-bedge-show')

    setTimeout(() => {
      document.getElementsByClassName('copy-bedge')[0].classList.remove('copy-bedge-show')
    }, 2000)
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

  selectFile(event, type) {
    this.fieldimg = type;
    this.selectedImgBgFiles = event.target.files;
    this.compress(this.selectedImgBgFiles[0]);
  }
  previewImgBgFiles(files) {
    if (files.length === 0) {
      return;
    }

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    var reader = new FileReader();
    this.imagePathBg = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  onClickImg(id) {
    document.getElementById(id).click();
  }
  compress(file) {
    let image: File = file;
    console.log(`Image size before compressed: ${image.size} bytes.`)

    this.selectedImgBgFiles = undefined;

    this.itemImage = { id: UUID.UUID(), name: file.name };

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];
      
    this.currentFileUpload = new FileUpload(file);
    this.percentage = 5;

    this.compressImage.compress(image)
      .pipe(take(1))
      .subscribe(compressedImage => {
        console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
        this.uploadImageBg(compressedImage);
        // now you can do upload the compressed image 
      })
  }
  uploadImageBg(image) {

    if (!image)
    return;
    
    const file = image;
    this.selectedImgBgFiles = undefined;

    this.itemImage = { id: UUID.UUID(), name: file.name };

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];


    this.currentFileUpload = new FileUpload(file);
    this.uploadService.pushFileToStorage(this.currentFileUpload, this.itemImage, 'images').subscribe(
      percentage => {
        this.percentage = Math.round(percentage && percentage > 10?percentage:10);

        if (this.percentage == 100) {

          if (this.itemImage['id'] && this.itemImage['id'] != '') {
            this.dataService.getItemByID('images', this.itemImage['id']).subscribe(dataimg => {

              if (dataimg) {
                this.imgURL = dataimg['file_url'];
                this.profil[this.fieldimg] = this.imgURL;
                //this.save();
                this.currentFileUpload = undefined;
              }
            });
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  clearImageBg() {
    this.selectedImgBgFiles = undefined;
    delete this.selectedImgBgFiles;
    (<HTMLInputElement>document.getElementById('fileinputimgbg')).value = '';

    // if (this.currentprop['properties']['background-image']) {
    //     this.imgURL = this.currentprop['properties']['background-image'];
    // } else {
    //     this.imgURL = undefined;
    // }
  }

  deleteImageBg() {
    this.selectedImgBgFiles = undefined;
    this.imgURL = undefined;

    delete this.selectedImgBgFiles;
    delete this.imgURL;
  }

  setProfile(field) {
    this.profil['profilimg'] = field;
  }

  deleteImg(field){
    this.user['profile'][field] = '';
    let item_ = { id: this.user['id'], profile: this.user['profile'] }
    this.dataService.saveItem("users", item_)
  }


  cancelevent(){
    if (!confirm('Doriti sa nu mai participati la eveniment?'))
    return;

    let item_ = { id: this.user['id'], cancel: true }
    this.dataService.saveItem("users_event", item_)
  }

  // cancelevent(){
  //   if (!confirm('Doriti sa nu mai participati la eveniment?'))
  //   return;

  //   let item_ = { id: this.user['id'], cancel: true }
  //   this.dataService.saveItem("users_event", item_)
  // }
}
