import {Component, OnInit, AfterViewInit, ElementRef} from '@angular/core';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ydaw';

  constructor(private ngxRolesService: NgxRolesService,
              private afStore: AngularFirestore,
              private elementRef:ElementRef,
              private authService: AuthService) {

  }

  bNavbar;

  ngOnInit(): void {
    
    this.authService.getCurrentUserRole().subscribe((data)=>{
      console.log(data);
      if(data){
        this.ngxRolesService.flushRoles();
        this.ngxRolesService.addRole(data.name, ()=>{
          return true;
        });
      }
    });
    this.ngxRolesService.roles$.subscribe((data)=>{
      console.log(data);
    });
  }

  ngAfterViewInit() {
    this.addscript(this.elementRef.nativeElement, "assets/js/jquery.cookiebar.js", "jquerycookiebarjs");
    this.addscript(this.elementRef.nativeElement, "assets/js/loadcookie.js", "loadcookiejs");
    // this.addscript(this.elementRef.nativeElement, "assets/js/core.min.js", "coreminjs");
    // this.addscript(this.elementRef.nativeElement, "assets/js/script.js", "scriptjs");
    this.addscript(this.elementRef.nativeElement, "assets/js/app.js", "appjs");
  }

  addscript(_elem, _file, _class, _class_general = ''){

    let content = document.getElementsByTagName('body')[0];
    let arr = document.getElementsByClassName(_class);
    for (let i = 0; i < arr.length; i++) {
    arr[i].remove();
    i = i-1;
    }

    var s = document.createElement("script");
    s.type = "text/javascript"; //"text/javascript";

    s.classList.add(_class);
    if (_class_general != '')
    s.classList.add(_class_general);

    s.src = _file;
    _elem.appendChild(s);

    return s;
}
}
