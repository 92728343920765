import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-footerpage',
  templateUrl: './footerpage.component.html',
  styleUrls: ['./footerpage.component.scss']
})

export class FooterPageComponent implements OnInit {

  currentUser;
  loaded;

  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth, private router: Router,
    private elementRef: ElementRef) { }

  ngOnInit(): void {

  }

}
