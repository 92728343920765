<section class="section jumbotron-2 headerindex2">
  <div class="jumbotron-2-content context-dark bg-image">
    <div class="rd-navbar-wrap" style="z-index: 0;">
      <nav class="rd-navbar rd-navbar-modern navbarcustom">
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main">
            <div class="rd-navbar-panel">
              <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
              <div class="rd-navbar-brand"><a class="brand" href="index.html"><img class="brand-logo-light"
                    src="assets/images/logo-inverse-316x92.png" alt="" width="158" height="46" /></a>
              </div>
            </div>
            <div class="rd-navbar-nav-wrap">
              <ul *ngIf="!currentUser" class="rd-navbar-nav">
                <li class="rd-nav-item aboutmenu"><a class="rd-nav-link" href="/auth/login" style="cursor: pointer;">
                    <span>Autentificare/Inregistrare</span></a>
                </li>
              </ul>
              <ul *ngIf="currentUser" class="rd-navbar-nav">
                <li class="rd-nav-item homemenu"><a class="rd-nav-link" href="/events">Evenimente</a></li>
                <li class="rd-nav-item aboutmenu">
                  <a class="rd-nav-link" [routerLink]="['/profile']">
                    <i class="fa fa-user"></i>
                    <span class="notmobile">{{'Profil'}}</span>
                    <!-- currentUser['displayName'] ||  -->
                  </a>
                </li>
                <li class="rd-nav-item aboutmenu"><a class="rd-nav-link" (click)="logout()" style="cursor: pointer;"><i
                      class="fa fa-sign-out"></i> <span class="notmobile">Iesire</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<ng-container>

  <section class="section section-lg bg-default text-center secsd">
    <div class="container" style="padding: 0px;">
      <div *ngIf="loaded" class="jumbotron-2-blockx" style="padding: 20px 0px 0px 0px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Administrare evenimente</h2>
          <p style="color: orangered; margin-bottom: 20px;">
            Pentru a adauga, valida si confirma un eveniment, este necesar sa contactati un reprezentant!
            <a href="https://www.facebook.com/VreauRelatieSerioasa" target="_blank"><i class="fa fa-facebook-official" style="color: cornflowerblue;margin-left: 10px;left: 50%;transform: translateX(-50%);"></i></a>
          </p>

          <div class="col-md-4 offset-md-4">
            <label>Selecteaza eveniment</label>
            <select (change)="eventselect($event)">
              <option value="">selecteaza</option>
              <option *ngFor="let event of events" value="{{event.id}}">{{event.title}}</option>
            </select>
          </div>
        </div>

        <div class="row fields" style="display: none;">
          <div class="col-md-2">
            <label>Caut</label>
            <select placeholder="gen" [(ngModel)]="gen" (change)="savefilters()">
              <option value="">selecteaza</option>
              <option value="Femeie">Femeie</option>
              <option value="Barbat">Barbat</option>
            </select>
          </div>
          <div class="col-md-4">
            <label>Status relatie</label>
            <select [(ngModel)]="relatie" (change)="savefilters()">
              <option value="">selecteaza</option>
              <option value="Sunt singur">Singur(a)</option>
              <option value="Am pe cineva/sunt aici pentru prieteni">Are pe cineva/aici pentru prieteni</option>
              <option value="Casatorit/sunt aici pentru prieteni">Casatorit/aici pentru prieteni</option>
            </select>
          </div>

          <div class="col-md-3">
            <label>Educatie</label>
            <select [(ngModel)]="educatie" (change)="savefilters()">
              <option value="">selecteaza</option>
              <option value="Gimnaziu">Gimnaziu</option>
              <option value="Scoala de arte si meserii">Scoala de arte si meserii</option>
              <option value="Liceu">Liceu</option>
              <option value="Universitate">Universitate</option>
              <option value="Diploma postuniversitara">Diploma postuniversitara</option>
            </select>
          </div>

          <div class="col-md-3">
            <label>Localitate</label>
            <select placeholder="judet" [(ngModel)]="localitate" (change)="savefilters()">
              <option value="">selecteaza</option>
              <option value="Alba">Alba</option>
              <option value="Arad">Arad</option>
              <option value="Arges">Arges</option>
              <option value="Bacau">Bacau</option>
              <option value="Bihor">Bihor</option>
              <option value="Bistrita-Nasaud">Bistrita-Nasaud</option>
              <option value="Botosani">Botosani</option>
              <option value="Braila">Braila</option>
              <option value="Brasov">Brasov</option>
              <option value="Bucuresti">Bucuresti</option>
              <option value="Buzau">Buzau</option>
              <option value="Calarasi">Calarasi</option>
              <option value="Caras-Severin">Caras-Severin</option>
              <option value="Cluj">Cluj</option>
              <option value="Constanta">Constanta</option>
              <option value="Covasna">Covasna</option>
              <option value="Dambovita">Dambovita</option>
              <option value="Dolj">Dolj</option>
              <option value="Galati">Galati</option>
              <option value="Giurgiu">Giurgiu</option>
              <option value="Gorj">Gorj</option>
              <option value="Harghita">Harghita</option>
              <option value="Hunedoara">Hunedoara</option>
              <option value="Ialomita">Ialomita</option>
              <option value="Iasi">Iasi</option>
              <option value="Ilfov">Ilfov</option>
              <option value="Maramures">Maramures</option>
              <option value="Mehedinti">Mehedinti</option>
              <option value="Mures">Mures</option>
              <option value="Neamt">Neamt</option>
              <option value="Olt">Olt</option>
              <option value="Prahova">Prahova</option>
              <option value="Salaj">Salaj</option>
              <option value="Satu-Mare">Satu-Mare</option>
              <option value="Sibiu">Sibiu</option>
              <option value="Suceava">Suceava</option>
              <option value="Teleorman">Teleorman</option>
              <option value="Timis">Timis</option>
              <option value="Tulcea">Tulcea</option>
              <option value="Valcea">Valcea</option>
              <option value="Vaslui">Vaslui</option>
              <option value="Vrancea">Vrancea</option>
            </select>
          </div>

        </div>

        <div *ngIf="eventselected" style="text-align: center; margin: 25px 0px;">
          <!-- <span (click)="deletefilters()" style="color: orangered;padding: 10px;cursor: pointer;">sterge filtrele</span>
          <div style="display: flex; flex-flow: column;">
            <label for="showall">Persoanele ascunse</label>
            <input type="checkbox" id="showall" [(ngModel)]="afiseazatot" (change)="savefilters()" name="showall">
          </div> -->
          <h3>{{eventselected.title}}</h3>
          <p>{{eventselected.description}}</p>
          <br>
          <span style="background: orangered;
          color: white;
          padding: 5px 10px;
          border-radius: 10px;">{{bWantEvent.length}} persoane
          </span>
        </div>

        <div *ngIf="list && list.length > 0" class="container text-center" style="padding: 0px;">
          <!-- <h4 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;color: coral;">{{list.length}} compatibilitati</h4> -->

          <div class="row row-30 row-xl-50 mt-md-45 mt-xxl-60">
            <div class="col-sm-6 col-lg-4 listusers" *ngFor="let item of getlist()">

              <article (click)="viewImages(item)" class="tour-3 bg-image context-dark wow fadeIn"
                style="visibility: visible; animation-name: fadeIn;"
                [ngStyle]="{'background-image': item['profile'] && item['profile']['img']?'url('+item['profile']['img']+')':'url(assets/images/noperson.jpg)'}">
                <div class="tour-3-inner">
                  <div class="tour-3-main" style="text-align: center;">
                    <!-- <span *ngIf="item['success']">compatibil</span> -->
                    <i (click)="setProfile(item)" class="fa fa-info-circle" title="info"
                      style="position: absolute;top: 20px;left: 20px; font-size: 30px;color:cornflowerblue;"></i>
                      <i *ngIf="!item['like']" class="fa fa-comment" (click)="setchat(item)" title="like"
                      style="position: absolute;top: 20px;right: 20px; font-size: 30px;color:orangered;"></i>
                    <!-- <i *ngIf="!item['like']" class="fa fa-thumbs-o-up" (click)="setlike(item)" title="like"
                      style="position: absolute;top: 20px;right: 40px; font-size: 30px;"></i>
                    <i *ngIf="item['like']" class="fa fa-thumbs-up" (click)="setunlike(item)" title="unlike"
                      style="position: absolute;top: 20px;right: 40px;color: #00ca00; font-size: 30px;"></i> -->

                    <!-- <i *ngIf="!item['hide']" class="fa fa-eye-slash" (click)="sethide(item)" title="hide"
                      style="position: absolute;bottom: 20px;right: 10px; font-size: 30px;"></i>
                    <i *ngIf="item['hide']" class="fa fa-eye" (click)="setunhide(item)" title="hide"
                      style="position: absolute;bottom: 20px;right: 10px; font-size: 30px;"></i> -->
                    <!-- #ae01dc -->
                    <h3 class="tour-3-title"><a>{{getFirstUpper(item['profile']['nume'])}}</a></h3>
                    <ul class="tour-3-meta">
                      <!-- <li><span class="icon mdi material-design-clock100"></span><span>active 10 days ago</span></li> -->
                      <!-- <li data-toggle="modal" data-target="#chat"><i class="fa fa-comments-o"
                          aria-hidden="true"></i><span>chat</span></li> -->
                    </ul>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="section bg-default" style=" background: #FAFAFA; padding: 50px 0;">
    <div class="container">
      <div class="row row-50 justify-content-center align-items-center text-center">
        <div class="col-md-12 flex-column justify-content-center">
          <h2 class="">Restrictionam utilizatorii pentru situatiile</h2>
          <div class="heading-3 ls-ng-1 offset-xl-t-40 text-gray-600">
            <ul class="list-marked col-md-4 offset-md-4" style="font-size: 20px;">
              <li class="li-alternate">un utilizator nu raspunde pe chat</li>
              <li class="li-alternate">vocabular nepotrivit</li>
              <li class="li-alternate">persoane fara interes pentru o relatie</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section> -->

</ng-container>

<div class="modal profile" id="profile">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Profil</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="profileclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: auto;padding: 20px 50px;">
        <app-profile [modal]="true"></app-profile>
      </div>

    </div>
  </div>
</div>

<div class="modal profile" id="profileview">
  <div *ngIf="currentprofil" class="modal-dialog" style="max-width: 1100px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>{{getFirstUpper(currentprofil['profile']['nume'])}} -
            profil</span></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="profileclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: auto;padding: 20px 50px;">
        <app-profileview [modal]="true" [userid]="currentprofil['id']"></app-profileview>
      </div>

    </div>
  </div>
</div>

<div class="modal profile" id="imageview">
  <div *ngIf="currentprofil" class="modal-dialog" style="max-width: 1100px;">
    <div class="modal-content" style="background: #ffffffd9 !important;">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>{{getFirstUpper(currentprofil['profile']['nume'])}} - {{nrimages()}} poze</span></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="profileclose" aria-hidden="true"></span>
        </button>
      </div> -->

      <div class="modal-body" style="overflow: auto;padding: 0px;">

        <h5 class="modal-title" id="exampleModalLabel" style="position: absolute; left: 10px;">
          <span>{{getFirstUpper(currentprofil['profile']['nume'])}} - {{nrimages()}} poze</span></h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="profileclose" aria-hidden="true"></span>
        </button>

        <span (click)="previmg()" class="previmg"
          style="position: absolute; top: 50%;left: 20px;color: blueviolet;cursor: pointer;"> <i
            class="fa fa-arrow-left"></i> </span>
        <span (click)="nextimg()" class="nextimg"
          style="position: absolute; top: 50%;right: 20px;color: blueviolet;cursor: pointer;"> <i
            class="fa fa-arrow-right"></i></span>

        <!-- <img *ngIf="currentprofil['profile']['img1']" style="width: 50px;" [src]="currentprofil['profile']['img1']" />
        <img *ngIf="currentprofil['profile']['img2']" style="width: 50px;" [src]="currentprofil['profile']['img2']" />
        <img *ngIf="currentprofil['profile']['img3']" style="width: 50px;" [src]="currentprofil['profile']['img3']" />
        <img *ngIf="currentprofil['profile']['img4']" style="width: 50px;" [src]="currentprofil['profile']['img4']" /> -->

        <div *ngIf="currentprofil['profile'][currentimg]" style="    background-position: center;
  background-size: contain;
    background-repeat: no-repeat;
         width: 100%;
        height: 100%;" [ngStyle]="{'background-image': 'url('+currentprofil['profile'][currentimg]+')'}"></div>
      </div>

    </div>
  </div>
</div>

<div class="modal profileuser" id="profileuser">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Profile</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="chatclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: hidden;padding: 20px 50px;">
        <app-profileuser></app-profileuser>
      </div>

    </div>
  </div>
</div>


<div class="modal chat" id="chat">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Chat</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span id="chatclose" aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body" style="overflow: hidden;padding: 20px 50px;">
        <app-chat></app-chat>
      </div>

    </div>
  </div>
</div>