import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from './auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
@Directive({
  selector: '[appShowAuth]'
})
export class ShowAuthDirective implements OnInit{
  condition: boolean;
  @Input() set appShowAuth(condition: boolean) {
    this.condition = condition;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.afAuth.authState.subscribe((isAuthenticated)=>{
      if (isAuthenticated && this.condition || !isAuthenticated && !this.condition) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
