import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompleteAccountResolverService implements Resolve<boolean>{

  constructor(private authService: AuthService, private router: Router) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getCurrentUserRole().pipe(
      take(1),
      map(role => {
        console.log(role);
        if(!!role){
          return role;
        } else {
          return this.router.navigate(['/auth/complete']);
        }
      })
    );
  }
}
