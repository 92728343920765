import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthModule} from '../../auth/auth.module';
import {ShowAuthDirective} from '../../auth/show-auth.directive';
import {FormsModule} from '@angular/forms';
import { AvatarModule } from 'ngx-avatar';


@NgModule({
    declarations: [
        ShowAuthDirective,
    ],
  exports: [
    ShowAuthDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    AvatarModule
  ]
})
export class SharedModule { }
