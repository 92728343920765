import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<any>;

  authState: any = null;

  constructor(private afAuth: AngularFireAuth, private fbsStore: AngularFirestore,) {

  }

  get authenticated(): boolean {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          console.log('switchMap data...', user);
          return this.fbsStore.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }));

    this.user.subscribe((auth) => {
      console.log('Subscribe data...', auth);
      this.authState = auth;
    });
    return this.afAuth.auth.currentUser !== null;
  }

  public get currentUser() {
    return this.authenticated ? this.authState : null;
  }

  loginEmail(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).then(
      user => user
    ).catch(
      error => error
    );
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  isLoggedIn() {
    return this.afAuth.auth.currentUser !== null;
  }

  async sendEmailVerification() {
    return await this.afAuth.auth.currentUser.sendEmailVerification();
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail);
  }

  getCurrentUserRole() {
    return this.afAuth.authState.pipe(
      switchMap((currentUser)=>{
        if (currentUser) {
          const currentUserRef = (this.fbsStore.doc(`users/${currentUser.uid}`) as AngularFirestoreDocument<any>).valueChanges();
          return currentUserRef.pipe(
            switchMap((data) => {
              if (data && data.type) {
                return this.fbsStore.doc(`roles/${data.type}`).valueChanges();
              } else {
                return of(null);
              }
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

}
