<footer class="section footer-modern footerindex2">
  <div class="footer-modern-main" style="padding: 0px;">
    <div class="container">
      <p style="font-size: 13px;text-align: center;">Varsta minima pentru a utiliza aceasta platforma este 18 ani.
        Platforma este o retea sociala pentru barbati si femei, care cauta sa intalneasca persoane compatibile.
        Decizia de a intalni sau nu persoanele este intotdeauna la latitudinea membrilor nostri.
        Inregistrarea si crearea profilului dumneavoastra sunt gratuite.
        Nu va trebui sa platiti un abonament pentru a utiliza platforma noastra.</p>
    </div>
    <a href="https://www.facebook.com/groups/precis" target="_blank"><i class="fa fa-facebook-official"
        style="color: cornflowerblue;position: absolute;left: 50%;transform: translateX(-50%);"></i></a>
  </div>
  <div class="footer-modern-aside">
    <div class="container">
      <div class="row row-30">
        <div class="col-lg-4 d-flex flex-column align-items-lg-start justify-content-center align-items-center order-1">
        </div>
        <div class="col-lg-6 d-flex flex-column align-items-lg-start justify-content-center order-3 order-lg-4">
          <p class="rights" style="color: #cdc9c9;"><span> </span><span
              class="copyright-year"><span>PRECIS</span></span><span>&nbsp; &copy;</span>2024<span> Toate drepturile
              rezervate.&nbsp;</span></p>
        </div>
      </div>
    </div>
  </div>
</footer>