
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {ListComponent} from './list/list.component';
import {List2k1Component} from './2k1/2k1.component';
import {EventComponent} from './event/event.component';
import {EventAdminComponent} from './eventadmin/eventadmin.component';
import {GroupAdminComponent} from './groupadmin/groupadmin.component';
import {EventsComponent} from './events/events.component';
import {GroupsComponent} from './groups/groups.component';
import {ProfileComponent} from './profile/profile.component';
import {ProfileViewComponent} from './profileview/profileview.component';
import {ProfileUserComponent} from './profileuser/profileuser.component';
import {ChatComponent} from './chat/chat.component';
import {GdprComponent} from './gdpr/gdpr.component';
import {CompleteAccountResolverService} from './auth/complete-account-resolver.service';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home', 
    component: ChatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'main', 
    component: ListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '2k1', 
    component: List2k1Component,
  },
  {
    path: 'users', 
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'eventadmin', 
    component: EventAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'groupadmin', 
    component: GroupAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events', 
    component: EventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'groups/:groupid', 
    component: GroupsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'eveniment', 
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile', 
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profileview', 
    component: ProfileViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profileuser', 
    component: ProfileUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat/:userid/:type', 
    component: ChatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    resolve: {
      data: CompleteAccountResolverService
    },
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'gdpr',
    component: GdprComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
