<app-headerpage></app-headerpage>

<section *ngIf="currentUser && chat" class="secsd">
    <div class="container">

      <div *ngIf="!chatSelected" class="chat_window">
        <div style="background: #00bcd4; color: white; text-indent: 10px;">
          Chat - alege un utilizator
        </div>
        <div *ngFor="let user of users" class="useritem" (click)="setchat(user)">
          {{user['name']}}
          <span *ngIf="user['new']" class="newmsg"></span>
        </div>
      </div>
      <div *ngIf="chatSelected" class="chat_window">
        <div *ngIf="type == 1" (click)="listusers()" style="background: #00bcd4; color: white; text-indent: 10px;position: relative;">
          <i class="fa fa-arrow-left"></i> Lista utilizatori
          <span class="nameuser">{{chatSelected['name']}}</span>
        </div>
        <div *ngIf="type == 2" [routerLink]="['/events']" style="background: #00bcd4; color: white; text-indent: 10px;">
          <i class="fa fa-arrow-left"></i> Lista evenimente
        </div>
        <ul class="messages">
          <img *ngIf="chat.length == 0" src="assets/images/loading.gif" class="loading" />
          <li class="message appeared" *ngFor="let msg of chat | sort:'date'"
            [ngClass]="[msg['ownerid']==currentUser['uid']?'right':'left']">
            <div class="avatar"></div>
            <div class="text_wrapper">
              <div class="text">{{msg['message']}}</div>
            </div>
          </li>
        </ul>
        <div class="bottom_wrapper clearfix">
          <div class="message_input_wrapper">
            <input class="message_input" placeholder="scrie..." (keydown.enter)="addMessage()"
              [(ngModel)]="msgwrite['message']">
          </div>
          <div class="send_message">
            <div class="icon"></div>
            <div class="text" (click)="addMessage()">Send</div>
          </div>
        </div>
      </div>

    </div>
</section>