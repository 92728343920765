<section class="section jumbotron-2 headerindex" style="position: fixed; width: 100%; z-index: 1000; display: block;">
  <div class="jumbotron-2-content context-dark bg-image">
    <div class="rd-navbar-wrap" style="height: 113.391px;">
      <nav class="rd-navbar rd-navbar-modern rd-navbar-original rd-navbar-static">
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main rd-navbar-main1">
            <div class="rd-navbar-panel">
              <div class="rd-navbar-brand"><a href="index.html" class="brand index"><img
                    src="assets/images/logo-inverse-316x92.png" alt="" width="158" height="46"
                    class="brand-logo-dark"><img src="assets/images/logo-default-316x92.png" alt="" width="158"
                    height="46" class="brand-logo-light"></a></div>
            </div>
            <div class="rd-navbar-nav-wrap">
              <ul class="rd-navbar-nav" style="    display: flex;
              flex-flow: row;
              justify-content: flex-end;">
                <li class="rd-nav-item aboutmenu isnotmobile"><a href="/auth/login"
                    class="rd-nav-link">Autentificare/Inregistrare</a></li>
                <li class="rd-nav-item aboutmenu isnotmobile"><a href="/blog/index.html" 
                      class="rd-nav-link">Blog</a></li>
                <li class="rd-nav-item aboutmenu ismobile" style="    padding-right: 30px;"><a href="/auth/login"
                    class="rd-nav-link">Cont</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<ng-container>

  <section class="section jumbotron-2 home-bg">
    <div class="jumbotron-2-content context-dark bg-image">

      <div class="container" style="padding-top: 75px;">
        <div class="jumbotron-2-content-inner">
          <div class="row justify-content-start">
            <div class="col-xl-5 textmobile" style="padding: 0px;">
              <h1 class="text-white" style="color: orangered !important;">PRECIS</h1>
              <!-- <div class="col-md-2 col-md-offset-5 imgfree"> <img src="assets/images/free2.png" alt=""> </div> -->
              <p class="text-white-8 text-md intro-subtitle ls-ng-1" style="padding-top: 10px;">Cunoaste persoane
                noi!<br>
                <span style="font-size: 17px;">Prieteni, Relaxare, Excursii, Calatorii, Iesiri, Socializare</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lgx bg-default text-center">
    <div class="container col-md-12 divmobile" style=" 
    display: flex;
    margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6 col-12" style="padding: 20px;
      /* background: #e0f3f1; */
      border-radius: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Evenimente</h2>
          <p class="big"> Dorim sa promovam in comunitatea noastra evenimente unde poti cunoaste persoane noi si
            astfel sustinem motto-ul <br> <span style="color: orangered;">"Cunoaste persoane noi!"</span> <br>
            La un eveniment poti vizualiza profilul participantilor!
          </p>
          <!-- <div class="row fields">
            <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
                style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
            </div>
          </div> -->
        </div>
      </div>

      <div class="jumbotron-2-blockx col-md-6 col-12">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <img src="assets/images/events.jpg" style="    display: inline-block;
    max-width: 100%;
    padding: 0px 10px;
    border-radius: 15px;" />
        </div>
      </div>

    </div>
  </section>

  <section class="section section-lgx bg-default text-center carddiv ismobile" style="margin-top: 0px !important;">
    <div class="container col-md-12 divmobile2" style=" 
    display: flex;
    margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6 col-12">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Card Standard</h2>
          <p class="big"> Cardul de membru PRECIS este biletul tău către experiența perfectă de socializare! Prin acest
            card, nu doar că ai acces la evenimente și activități sociale în clubul nostru, dar devii și beneficiarul
            unor reduceri speciale prin parteneriatele noastre cu diverse afaceri locale.
          </p>
          <!-- <div class="row fields">
          <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
              style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
          </div>
        </div> -->
        </div>
      </div>

      <div class="jumbotron-2-blockx col-md-6 col-12" style="padding-top: 50px;     padding-bottom: 100px;
      /* background: #e0f3f1; */
      border-radius: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">

          <div class="flip-card">
            <div class="flip-card-inner-stop flipmobile">
              <div class="flip-card-front">
                <img src="assets/images/card/precis-card-spate.jpg" style="    display: inline-block;
                max-width: 100%;
                border-radius: 15px;" />
              </div>
              <div class="flip-card-back">
                <img src="assets/images/card/precis-card-fata.jpg" style="    display: inline-block;
                max-width: 100%;
                border-radius: 15px;" />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>

  <section class="section section-lgx bg-default text-center isnotmobile">
    <div class="container col-md-12" style="    padding: 50px 50px !important;
  display: flex;
  margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6" style="margin-top: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">

          <div class="flip-card">
            <div class="flip-card-inner-stop notflipmobile">
              <div class="flip-card-front">
                <img src="assets/images/card/precis-card-spate.jpg" style="    display: inline-block;
      max-width: 100%;
      border-radius: 15px;" />
              </div>
              <div class="flip-card-back">
                <img src="assets/images/card/precis-card-fata.jpg" style="    display: inline-block;
      max-width: 100%;
      border-radius: 15px;" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="jumbotron-2-blockx col-md-6" style="padding: 20px;
    /* background: #e0f3f1; */
    border-radius: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Card Standard</h2>
          <p class="big"> Cardul de membru PRECIS este biletul tău către experiența perfectă de socializare! Prin acest
            card, nu doar că ai acces la evenimente și activități sociale în clubul nostru, dar devii și beneficiarul
            unor reduceri speciale prin parteneriatele noastre cu diverse afaceri locale.
          </p>
          <!-- <div class="row fields">
          <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
              style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
          </div>
        </div> -->
        </div>
      </div>

    </div>
  </section>

  <section class="section section-lgx bg-default text-center ismobile" style="margin-top: 0px !important; margin-top: 100px !important;
  margin-bottom: 250px !important;">
    <div class="container col-md-12 divmobile2" style=" 
    display: flex;
    margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6 col-12">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Card Vip</h2>
          <p class="big">Cardul VIP de membru PRECIS aduce beneficii deosebite utilizatorilor săi. 
            Organizatorii de evenimente îl utilizează pentru a recompensa și fideliza membri de elită, oferindu-le privilegii precum participarea la întâlniri exclusive și accesul la oferte personalizate. 
          </p>
          <!-- <div class="row fields">
          <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
              style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
          </div>
        </div> -->
        </div>
      </div>

      <div class="jumbotron-2-blockx col-md-6 col-12" style="padding-top: 50px; 
      /* background: #e0f3f1; */
      border-radius: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">

          <div class="flip-card">
            <div class="flip-card-inner-stop2 flipmobile">
              <div class="flip-card-front">
                <img src="assets/images/card/precis-card-spate-vip.jpg" style="    display: inline-block;
                max-width: 100%;
                border-radius: 15px;" />
              </div>
              <div class="flip-card-back">
                <img src="assets/images/card/precis-card-fata-vip.jpg" style="    display: inline-block;
                max-width: 100%;
                border-radius: 15px;" />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>

  <section class="section section-lgx bg-default text-center isnotmobile">
    <div class="container col-md-12" style="    padding: 0px  50px 50px 50px !important;
  display: flex;
  margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6" style="padding: 20px; 
    /* background: #e0f3f1; */
    border-radius: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Card Vip</h2>
          <p class="big">Cardul VIP de membru PRECIS aduce beneficii deosebite utilizatorilor săi. 
            Organizatorii de evenimente îl utilizează pentru a recompensa și fideliza membri de elită, oferindu-le privilegii precum participarea la întâlniri exclusive și accesul la oferte personalizate. 
          </p>
        </div>
      </div>

      <div class="jumbotron-2-blockx col-md-6" style="margin-top: 15px;">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">

          <div class="flip-card">
            <div class="flip-card-inner-stop2 notflipmobile">
              <div class="flip-card-front">
                <img src="assets/images/card/precis-card-spate-vip.jpg" style="    display: inline-block;
      max-width: 100%;
      border-radius: 15px;" />
              </div>
              <div class="flip-card-back">
                <img src="assets/images/card/precis-card-fata-vip.jpg" style="    display: inline-block;
      max-width: 100%;
      border-radius: 15px;" />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>

  <section class="section" style="background: #fafafa; padding: 50px; ">
    <div class="range">
      <div class="col-md-12">
        <div class="cell-inner section-lg2 text-center text-sm-left">
          <h2 class="text-center text-xl-left wow fadeIn">Cunoaste persoane noi! <br> <span style="font-size: 25px;">-
              prietenie sau relatie -</span></h2>
          <!-- <p class="text-center text-xl-left wow fadeIn" data-wow-delay=".05s">
            Identificam pentru evenimente persoane noi si compatibile
          </p> -->
          <!-- Persoanele care nu raspund intr-o saptamana, vor avea contul blocat si va trebui sa achite o taxa pentru deblocare. -->
          <!-- Owl Carousel-->
          <div class="custom owl-1 list-group-1 mt-lg-50 row col-md-12x">
            <article class="col-md-4 wow fadeIn">
              <div class="icon lg-1-item-icon mdi material-design-compass106"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Cautare</h3>
                <p>Ca sa putem identifica persoane compatibile, este necesar sa completati profilul. Cautam atat in baza
                  de
                  date cat si prin partenerii nostri.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn">
              <div class="icon lg-1-item-icon mdi mdi-account-search"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Referinte</h3>
                <p>Pentru fiecare persoana care isi creaza cont, folosind link-ul Dvs. de referinta din profil, ai
                  <span style="color: orangered;">reducere 10 RON la evenimentele partenerilor</span>.
                </p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".05s">
              <div class="icon lg-1-item-icon mdi mdi-comment-processing"></div>
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Evenimente</h3>
                <p>Incurajăm persoanele active și sprijinim organizatorii de evenimente. Prin implicarea lor contribuie
                  la succesul fiecărui moment special.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lgx bg-default text-center">
    <div class="container col-md-12 divmobile" style=" 
    display: flex;
    margin: 0px;">

      <div class="jumbotron-2-blockx col-md-6 col-12">
        <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
          <img src="assets/images/precis-ai-asistent.jpg" style="    display: inline-block;
    max-width: 100%;
    padding: 0px 10px;
    border-radius: 15px;" />
        </div>
      </div>

    <div class="jumbotron-2-blockx col-md-6 col-12" style="padding: 20px;
    /* background: #e0f3f1; */
    border-radius: 15px;">
      <div class="container mt-30 mt-md-45 mt-xxl-75 text-center">
        <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">Asistent AI</h2>
        <p class="big">Asistentul AI virtual al comunității este un dispozitiv avansat, utilizând tehnologii de recunoaștere vocală și inteligență artificială pentru a oferi suport personalizat utilizatorilor. <br>
          Scopul acestui asistent este de a sugera activități pentru îmbunătățirea stării de bine și avansarea în carieră, de la recomandări de lectură a unor cărți, până la propuneri de participare la evenimente sociale relevante.
        </p>
        <!-- <div class="row fields">
          <div class="col-md-4 offset-md-4"><a class="btn btn-info" href="blog/evenimente.html" target="_blank"
              style="background: cornflowerblue; border-radius: 50px; margin-top: -15px;">Mai multe detalii</a>
          </div>
        </div> -->
      </div>
    </div>

  </div>
  </section>
  <!-- <section class="breadcrumbs-customx" style="background-image: url(assets/images/bg-image-2.jpg); background-size: cover; background-position: bottom; color: white; text-align: center;">
    <div class="breadcrumbs-custom-inner" style="padding: 50px 0px !important;">
      <div class="container">
        <div class="breadcrumbs-custom-main">
          <h2 class="breadcrumbs-custom-title" style="color: white !important;">2k1</h2>
          <p class="big">Oferim gratuit serviciile noastre in aceasta perioada! <br> Mai mult, daca esti o persoana potrivita pachetului 2k1, <br> serviciile noastre vor fi mereu gratuite (contacteaza un reprezentant)!
            <a href="https://www.facebook.com/VreauRelatieSerioasa" target="_blank"><i class="fa fa-facebook-official" style="color: cornflowerblue;margin-left: 10px;left: 50%;transform: translateX(-50%);"></i></a>
          </p>
        </div>
        <div class="row fields">
        <div class="col-md-4 offset-md-4">
          <button class="btn btn-info" [routerLink]="['/profile']"
            style="background: lightseagreen; border-radius: 50px; margin-top: -15px;">Completeaza profilul</button>
        </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- <section class="section bg-default" style=" background: #FAFAFA; padding: 50px 0;">
    <div class="container">
      <div class="row row-50 justify-content-center align-items-center text-center">
        <div class="col-md-12 flex-column justify-content-center">
          <h2 class="">Aplicam reguli pentru situatiile</h2>
          <div class="heading-3 ls-ng-1 offset-xl-t-40 text-gray-600">
            <ul class="list-marked col-md-4 offset-md-4" style="font-size: 20px;">
              <li class="li-alternate">un utilizator nu raspunde intr-o saptamana</li>
              <li class="li-alternate">vocabular nepotrivit</li>
              <li class="li-alternate">persoane fara interes pentru o relatie</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="section sectionbottom" style="background: #fafafa; padding: 50px 0px !important;">
    <div class="range">
      <div class="col-md-12">
        <div class="cell-inner section-lg2 text-center text-sm-left">
          <h2 class="text-center text-xl-left wow fadeIn">Persoane compatibile <br> <span style="font-size: 25px;">- in
              3 pasi -</span></h2>
          <!-- <p class="text-center text-xl-left wow fadeIn" data-wow-delay=".05s"> 
              In fiecare zi veti avea acces la 3 profile compatibile. <br>
              Persoanele care nu raspund intr-o saptamana, vor avea contul blocat si va trebui sa achite o taxa pentru deblocare.</p> -->
          <!-- Owl Carousel-->
          <div class="custom owl-1 list-group-1 mt-lg-50  row col-md-12x">
            <article class="col-md-4 wow fadeIn">
              <img src="assets/images/profile.jpg" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal">Completeaza profilul</h3>
                <p>Creeaza-ti profilul gratuit. Aloca-ti timpul necesar. Sansele tale de a-ti gasi persoana compatibila
                  (prietenie sau relatie)
                  vor creste semnificativ.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".025s">
              <img src="assets/images/safe.png" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Siguranta completa
                </h3>
                <p>Utilizatorii sunt confirmati de catre echipa noastra, pentru a ne asigura ca intri in contact cu
                  persoane reale, care cauta acelasi lucru ca tine.</p>
              </div>
            </article>
            <article class="col-md-4 wow fadeIn" data-wow-delay=".05s">
              <img src="assets/images/result.jpg" />
              <div class="lg-1-item-main">
                <h3 class="lg-1-item-title font-weight-normal"><span class="lg-1-item-counter"></span>Rezultate</h3>
                <p>Indiferent de motivul pentru care ne-ai ales, in comunitatea noastra vei avea parte cu siguranta de o
                  experienta placuta.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="section cta-section position-relative section-sm context-dark bg-img-2">
      <div class="container">
        <div class="row justify-content-lg-end justify-content-center">
          <div class="col-md-8 col-lg-5 d-flex flex-column justify-content-center wow fadeInRight">
            <h2>Premium Membership</h2>
            <p class="text-white-7">Enjoy the benefits of our dating services membership! Use the psychological matching to find the best people and experience other great bonuses.</p>
            <div class="unit cta-unit unit-spacing-md flex-column flex-sm-row align-items-center offset-xl-t-40">
              <div class="unit-left"><a class="button button-md button-light" href="#">view all options</a></div>
              <div class="unit-body"><span class="text-white-7">14 days for free</span></div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

</ng-container>

<app-footerpage></app-footerpage>