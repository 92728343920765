import { AvatarModule } from 'ngx-avatar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderPageComponent } from './share/headerpage/headerpage.component';
import { FooterPageComponent } from './share/footerpage/footerpage.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { PipesModule } from 'src/app/pipes/pipes.module'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { ListComponent } from './list/list.component';
import { List2k1Component } from './2k1/2k1.component';
import { EventComponent } from './event/event.component';
import { EventAdminComponent } from './eventadmin/eventadmin.component';
import { GroupAdminComponent } from './groupadmin/groupadmin.component';
import { EventsComponent } from './events/events.component';
import { GroupsComponent } from './groups/groups.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileViewComponent } from './profileview/profileview.component';
import { ProfileUserComponent } from './profileuser/profileuser.component';
import { ChatComponent } from './chat/chat.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { SharedModule } from './layout/shared/shared.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';


@NgModule({
  declarations: [
    AppComponent,
    HeaderPageComponent,
    FooterPageComponent,
    HomeComponent,
    ListComponent,
    List2k1Component,
    EventComponent,
    EventAdminComponent,
    GroupAdminComponent,
    EventsComponent,
    GroupsComponent,
    ProfileComponent,
    ProfileViewComponent,
    ProfileUserComponent,
    ChatComponent,
    GdprComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    GooglePlaceModule,
    NgbModule,
    NgSelectModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    AvatarModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    PipesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
