import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-headerpage',
  templateUrl: './headerpage.component.html',
  styleUrls: ['./headerpage.component.scss']
})

export class HeaderPageComponent implements OnInit, AfterViewInit {

  currentUser;
  loaded;

  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth, private router: Router,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

}
