import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service'

declare let $: any;

@Component({
  selector: 'app-2k1',
  templateUrl: './2k1.component.html',
  styleUrls: ['./2k1.component.scss']
})

export class List2k1Component implements OnInit, AfterViewInit {

  list;
  currentUser;
  submittedcom;

  loaded;
  msgcopy;genLinkID;

  constructor(
    private authService: AuthService, 
    private afAuth: AngularFireAuth, 
    private router: Router,
    private dataService: DataService
    ) { }

  ngOnInit(): void {
    let self = this;

  }

  ngAfterViewInit() {
    $('.headerindex').css('display', 'none');
    // $('.footerindex').css('display', 'none');
  }

  copylink(){
    let self = this;
    var copyText = <HTMLInputElement>document.getElementById("copylink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    this.msgcopy = "Copiat!";
    setTimeout(() => {
      self.msgcopy = '';
    }, 1000);
  }

}
