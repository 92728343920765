import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service'
declare let $: any;

import {FileUpload} from 'src/app/models/fileupload';
import {UploadFileService} from 'src/app/services/upload-file.service';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-profileview',
  templateUrl: './profileview.component.html',
  styleUrls: ['./profileview.component.scss']
})

export class ProfileViewComponent implements OnInit, AfterViewInit {

  @Input() modal:boolean=false;
  @Input() short:boolean=false;
  @Input() userid_:string;
  currentUser;
  submitted;

  profil={}; user;
  step_current=1;

  selectedImgBgFiles;
  public imagePathBg; imgURL: any;
  elemImage;message;
  imagePath;blobImg;
  fieldimg;

  currentFileUpload: FileUpload;
  percentage: number;
  itemImage={};
  startupload = false;

  loading; loadingadd; loadingbuilder = false;

  @Input()
  set userid(value){
    let self = this;
    this.userid_ = value;

    this.dataService.getItemByID("users",  this.userid_).subscribe((data) => {

      this.user = data;

      if (data && !data['profile'])
      data['profile'] = {};

      self.profil = data['profile'];
    });
  }

  constructor(
    private authService: AuthService, 
    private afAuth: AngularFireAuth, 
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private uploadService: UploadFileService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    $('.headerindex').css('display', 'none');
    // $('.footerindex').css('display', 'none');
  }

  back(){
    if (!this.step_current)
    return;

    if (this.step_current==1)
    return;

    if (this.step_current==3){

    }

    this.step_current = this.step_current -1;

    this.dataService.saveItem("users",  this.user, 'uid').then((data) => {
    });
  }

  next(){

    this.submitted = true;

    if (!this.step_current && this.step_current != 0)
    return;

    //billing details
    if (this.step_current === 1){
      this.submitted = true;

      if (!this.profil || !this.profil['nume'] || !this.profil['varsta'] || !this.profil['gen'])
      return;
    }

    if (this.step_current === 2){
 
    }

    if (this.step_current === 3){
 
    }

    if (this.step_current === 4)
    return;

    this.step_current = this.step_current+1;

    this.dataService.saveItem("users",  this.user, 'uid').then((data) => {
    });
  }

  save(){

    this.submitted = true;

    if (!this.profil || !this.profil['nume'] || !this.profil['varsta'] || !this.profil['gen']){
      this.step_current = 1;
      return;
    }
    
    this.user['profile'] = this.profil;

    this.dataService.saveItem("users",  this.user, 'uid').then((data) => {

      if (this.step_current == 4 && this.modal && document.getElementById('profileclose'))
      document.getElementById('profileclose').click()

      this.next();
    });
  }
  
  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

  selectFile(event, type) {
    this.fieldimg = type;
    this.selectedImgBgFiles = event.target.files;
    //this.previewImgBgFiles(this.selectedImgBgFiles);
    this.uploadImageBg();
}
previewImgBgFiles(files) {
    if (files.length === 0) {
        return;
    }

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
        this.message = 'Only images are supported.';
        return;
    }

    var reader = new FileReader();
    this.imagePathBg = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
        this.imgURL = reader.result;
    }
}

onClickImg(id) {
  document.getElementById(id).click();
}
uploadImageBg() {

    const file = this.selectedImgBgFiles.item(0);
    this.selectedImgBgFiles = undefined;

    this.itemImage = { id: UUID.UUID(), name: file.name };

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
        ext = file.name.split('.')[file.name.split('.').length - 1];


    this.currentFileUpload = new FileUpload(file);
    this.uploadService.pushFileToStorage(this.currentFileUpload, this.itemImage, 'images').subscribe(
        percentage => {
            this.percentage = Math.round(percentage);

            if (this.percentage == 100) {
                this.currentFileUpload = undefined;

                if (this.itemImage['id'] && this.itemImage['id'] != '') {
                    this.dataService.getItemByID('images', this.itemImage['id']).subscribe(dataimg => {

                        if (dataimg) {
                            this.imgURL = dataimg['file_url'];
                            this.profil[this.fieldimg] = this.imgURL;
                        }
                    });
                }
            }
        },
        error => {
            console.log(error);
        }
    );
}
clearImageBg() {
    this.selectedImgBgFiles = undefined;
    delete this.selectedImgBgFiles;
    (<HTMLInputElement>document.getElementById('fileinputimgbg')).value = '';

    // if (this.currentprop['properties']['background-image']) {
    //     this.imgURL = this.currentprop['properties']['background-image'];
    // } else {
    //     this.imgURL = undefined;
    // }
}

deleteImageBg() {
    this.selectedImgBgFiles = undefined;
    this.imgURL = undefined;

    delete this.selectedImgBgFiles;
    delete this.imgURL;
}

setProfile(field){
  this.profil['profilimg'] = field;
}


}
