<div _ngcontent-c4="" class="container margin_120_95" style="background: #f9f9f9; padding: 50px">
    <h1 _ngcontent-c4="" style="color: black; text-align: center;"
    >Agree to process and store personal data
</h1>
<br>
<p _ngcontent-c4="" style="text-indent: 30px;">Please read this agreement carefully.</p>
<p _ngcontent-c4="" style="text-indent: 30px;">By visiting the www.ydaw.ro site, when filling in the contact form or by accessing the services or products that we provide, we provide you with personal information. Thus, you agree unequivocally that you are informed about the storage and processing of your personal data by reading this page. Your agreement is freely consented and without any conditioning on our part. We make sure that we do not provide third-party mailing lists in order to obtain material benefits. As far as personal information is concerned, we do our best to always be safe. If we need to provide information to third parties, we will inform you beforehand. The www.ydaw.ro site becomes a personal data carrier in accordance with Law no. 677 of 2001 and EU Regulation 679/2016 (which also applies in Romania starting May 25, 2018).</p>
<p _ngcontent-c4="" style="text-indent: 30px;">In accordance with the legal provisions mentioned above, as a visitor you become the target person. This means a person who can be identified by name or location data as the IP address or geolocation, telephone number. In general, data collection (IP or geolocation) is done in order to provide an improved browsing experience and statistics to improve the presence in the online environment of the site. Any changes to the terms or conditions listed above will be made known to you by email.<br _ngcontent-c4=""></p>
<p _ngcontent-c4="">To exercise your rights, you can always send an email with your requests to office@ydaw.ro</p><div _ngcontent-c4="" class="pagination">
<a class="btn btn-primary" href="/">Home</a>
</div>
</div>