<section class="section jumbotron-2 headerindex2 isnotmobile">
  <div class="jumbotron-2-content context-dark bg-image">
    <div class="rd-navbar-wrap" style="z-index: 0;">
      <nav class="rd-navbar rd-navbar-modern navbarcustom">
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main rd-navbar-main2">
            <div class="rd-navbar-panel">
              <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
              <div class="rd-navbar-brand"><a class="brand" [routerLink]="['/main']"><img class="brand-logo-light"
                    src="assets/images/logo-inverse-316x92.png" alt="" width="158" height="46" /></a>
              </div>
            </div>
            <div class="rd-navbar-nav-wrap">
              <ul class="rd-navbar-nav">
                <li class="rd-nav-item homemenu"><a class="rd-nav-link" [routerLink]="['/main']" routerLinkActive="active">  <i class="fa fa-home"></i> Acasa</a></li>
                <li class="rd-nav-item homemenu"><a class="rd-nav-link" [routerLink]="['/users']" routerLinkActive="active">  <i class="fa fa-users"></i> Membri</a></li>
                <li class="rd-nav-item homemenu"><a class="rd-nav-link" [routerLink]="['/events']" routerLinkActive="active"> <i class="fa fa-calendar-o"></i> Evenimente</a></li>
                <li class="rd-nav-item aboutmenu">
                  <a class="rd-nav-link" [routerLink]="['/profile']" routerLinkActive="active">
                    <i class="fa fa-user"></i>
                    <span class="notmobile">{{'Profil'}}</span>
                    <!-- currentUser['displayName'] ||  -->
                  </a>
                </li>
                <li class="rd-nav-item aboutmenu"><a class="rd-nav-link" (click)="logout()" style="cursor: pointer;"><i
                      class="fa fa-sign-out"></i> <span class="notmobile">Iesire</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<div class="topnav ismobile">
  <a href="#home" class="active" style="padding: 0px;">
    <div class="rd-navbar-brand"><a class="brandx" [routerLink]="['/main']"><img class="brand-logo-light"
      src="assets/images/logo-inverse-316x92.png" alt="" style="width: 170px;" height="46" /></a>
</div>
  </a>
  <div id="myLinks">
    <ul class="rd-navbar-nav navmobile">
      <li class="rd-nav-item homemenu"><a class="rd-nav-link" [routerLink]="['/main']" routerLinkActive="active">  <i class="fa fa-home"></i> Acasa</a></li>
      <li class="rd-nav-item homemenu"><a class="rd-nav-link" [routerLink]="['/users']" routerLinkActive="active">  <i class="fa fa-users"></i> Membri</a></li>
      <li class="rd-nav-item homemenu"><a class="rd-nav-link" [routerLink]="['/events']" routerLinkActive="active"> <i class="fa fa-calendar-o"></i> Evenimente</a></li>
      <li class="rd-nav-item aboutmenu">
        <a class="rd-nav-link" [routerLink]="['/profile']" routerLinkActive="active">
          <i class="fa fa-user"></i>
          <span class="notmobile">{{'Profil'}}</span>
          <!-- currentUser['displayName'] ||  -->
        </a>
      </li>
      <li class="rd-nav-item aboutmenu"><a class="rd-nav-link" (click)="logout()" style="cursor: pointer;"><i
            class="fa fa-sign-out"></i> <span class="notmobile">Iesire</span></a></li>
    </ul>
  </div>
  <a href="javascript:void(0);" class="icon" style="font-size: 23px;
  margin-right: 10px;" onclick="setmenumobile()">
    <i class="fa fa-bars" style="font-size: 21px;"></i>
  </a>
</div>