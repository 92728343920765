import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit {

  currentUser;
  loaded;

  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth, private router: Router,
    private elementRef: ElementRef) { }

  ngOnInit(): void {

    this.afAuth.authState.subscribe((data) => {
      this.currentUser = data;

      // if (this.currentUser)
      // this.router.navigate(['/main']);
      // else
      // this.loaded = true;

    });
  }

  ngAfterViewInit() {
    $('.homemenu').addClass('active');

    $('.headerindex').css('display', 'block');
    $('.footerindex').css('display', 'block');

    //this.addscript(this.elementRef.nativeElement, "assets/js/jquery.cookiebar.js", "jquerycookiebarjs");
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/auth/login']);
    });
  }

  addscript(_elem, _file, _class, _class_general = ''){

    let content = document.getElementsByTagName('body')[0];
    let arr = document.getElementsByClassName(_class);
    for (let i = 0; i < arr.length; i++) {
    arr[i].remove();
    i = i-1;
    }

    var s = document.createElement("script");
    s.type = "text/javascript"; //"text/javascript";

    s.classList.add(_class);
    if (_class_general != '')
    s.classList.add(_class_general);

    s.src = _file;
    _elem.appendChild(s);

    return s;
}

}
