<section class="section jumbotron-2 headerindex2">
  <div class="jumbotron-2-content context-dark bg-image">
    <div class="rd-navbar-wrap" style="z-index: 0;">
      <nav class="rd-navbar rd-navbar-modern" style="display: block;background: orangered;border-bottom-color: #222;">
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main">
            <div class="rd-navbar-panel">
              <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
              <div class="rd-navbar-brand"><a class="brand" href="index.html"><img class="brand-logo-light"
                    src="assets/images/logo-inverse-316x92.png" alt="" width="158" height="46" /></a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<ng-container>

  <section class="section section-lgx bg-default text-center secsd">
    <div class="container">
      <div class="jumbotron-2-blockx" style="padding: 10px 0px 0px 0px;">
        <div *ngIf="!list || list.length == 0" class="container mt-30 mt-md-45 mt-xxl-75 text2k1" style="text-align: left;">
          <h2 class="wow fadeIn" style="visibility: visible; animation-name: fadeIn; text-align: center;">Evenimente "2k1"</h2>
          <p>	              
            Dorim sa lansam un nou tip de eveniment gratuit - "2k1". <br>
            Ne propunem ca fiecare membru sa isi gaseasca persoana potrivita. </p>
            
            <br>
            <b>Cum am ajuns la acest tip de eveniment?</b>
            <p>Am discutat cu mai multe persoane singure (20-30 persoane) si toate observatiile le-am analizat pentru a gasi o solutie potrivita. <br>
            Iesirile in oras(terasa, club) sau discutiile online, implica mult timp din partea fiecaruia si rezultatele nu sunt suficiente. <br>
            Acest lucru se intampla pentru ca oamenii nu au multa incredere in persoanele noi sau nu mai au rabdarea necesara sa cunoasca pe cineva. 
            Daca am gasi un alt context, unde sentimentul de incredere sa fie sporit, vom vedea ca mai multe persoane isi pot gasi persoana potrivita.</p>
            
            <br>
            <b>Cine participa?</b>
            <p>In primul rand, ne adresam persoanelor hotarate, serioase ce isi doresc o relatie serioasa. <br>
            De aceea multe persoane vrem sa ajunga la evenimente prin recomandare. <br>
            Evenimentele "2k1" vor fi gratuite, e indicat ca fiecare membru sa se implice sa aduca prin recomandare alte persoane. <br>
            Categoriile de varsta vor fi: 25-35 ani si 35-45 ani.</p>
            
            <br>
            <b>Cum procedam?</b>
            <p>Formam grupuri 4-6 fete si 4-6 baieti. <br>
            De preferat ca membri sa se cunoasca intre ei (adica o fata sa cunoasca deja alte fete si un baiat sa cunoasca deja alti baieti) <br>
            Se stabileste un loc de intalnire: biliard, terasa, parc si prin feedback-ul dat intre membri, folosind aplicatia noastra, vom transmite participantilor rezultatele compatibile.</p>
            
            <br>
            <p>Evenimentele sunt gratuite si organizate in parteneriat cu:</p>
              <ul>
                <li>
                  <a href="https://VreauRelatie.ro" target="_blank">VreauRelatie.ro</a>
                </li>
                <li>
                  <a href="https://OFaptaBuna.ro" target="_blank">OFaptaBuna.ro</a>
                </li>
                <li>
                  <a href="https://www.facebook.com/groups/precis" target="_blank">Grupul de facebook https://www.facebook.com/groups/PRECIS</a> <br>
                  <span style="font-size: 15px;color: gray;">(PRECIS - prieteni, relaxare, excursii, calatorii, iesiri, socializare)</span>
                </li>
              </ul>
        </div>
      </div>
      <div *ngIf="false" class="row">
        <div class="col-12">
          <!-- <h2>Mai multe persoane compatibile</h2> -->
          <!-- <p class="offset-xl-t-35">Cautam atat in baza de date cat si prin reprezentantii nostri.</p> -->
        </div>
      </div>
      <div *ngIf="false" class="row row-30 row-md-50 justify-content-center justify-content-lg-start">
        <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Comunitate</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Puteti forma un grup de persoane singure, si noi va ajutam sa organizati evenimente/discutii cu alte
                  grupuri <br> ( <span style="color: orangered;">online in perioada de pandemie</span> )</p>
              </div>

            </div>
          </blockquote>
        </div>
        <div class="col-md-6">
          <blockquote class="quote-classic">
            <div class="quote-classic-inner" style="text-align: center;">
              <div class="quote-classic-header" style="display: flex;justify-content: center;">
                <div class="quote-classic-profile">
                  <cite class="quote-classic-cite heading-3 ls-ng-1">Referinte</cite>
                </div>
              </div>
              <div class="quote-classic-text" style="text-align: center;">
                <p>Va cautam mai multe persoane compatibile, daca trimiteti link-ul de mai jos altor persoane singure.
                </p>
              </div>

              <p> <input *ngIf="currentUser" id="copylink" value="vreaurelatie.ro/auth/invite/{{currentUser['uid']}}"
                  class="copyinput readonly"> </p>
              <button class="btn btn-info" (click)="copylink()">copiaza link <i class="fa fa-copy"></i></button>
              <span *ngIf="msgcopy" style="margin-left: 10px;">{{msgcopy}}</span>

            </div>
          </blockquote>
        </div>
      </div>
    </div>
  </section>

  <section class="section bg-default" style=" background: #FAFAFA; padding: 50px 0;">
    <div class="container">
      <div class="row row-50 justify-content-center align-items-center text-center">
        <div class="col-md-12 flex-column justify-content-center">
          <h2 class="">Restrictionam utilizatorii pentru situatiile:</h2>
          <div class="heading-3 ls-ng-1 offset-xl-t-40 text-gray-600">
            <ul class="list-marked col-md-4 offset-md-4" style="font-size: 20px;">
              <li class="li-alternate">un utilizator nu raspunde intr-o saptamana</li>
              <li class="li-alternate">vocabular nepotrivit</li>
              <li class="li-alternate">persoane fara interes pentru o relatie</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</ng-container>