<div class="checkout-panel">
    <div class="panel-body">
          <div *ngIf="profil" class="row fields">
  
            <div class="col-md-4">
              <label for="fullname">Nume</label>
              <span>{{profil['nume'] || ''}}</span>
            </div>

            <!-- <div class="col-md-4">
              <label for="fullname">Telefon</label>
              <span>{{profil['telefon'] || ''}}</span>
            </div> -->
  
            <div class="col-md-4">
              <label for="email">Varsta</label>
              <span>{{profil['varsta'] || ''}}</span>
            </div>
  
            <div class="col-md-4">
              <label for="email">Gen</label>
              <span>{{profil['gen'] || ''}}</span>
            </div>
  
            <div class="col-md-4">
              <label for="email">Localitate</label>
              <span>{{profil['localitate'] || ''}}</span>
            </div>
  
            <div class="col-md-4">
              <label for="email">Status relatie</label>
              <span>{{profil['relatie'] || ''}}</span>
            </div>
  
            <div class="col-md-4">
              <label for="email">Educatie</label>
              <span>{{profil['educatie'] || ''}}</span>
            </div>
  
            <div class="col-md-4">
              <label for="email">Munca</label>
              <span>{{profil['munca'] || ''}}</span>
            </div>

            <div class="col-md-4">
              <label for="email">Silueta</label>
              <span>{{profil['silueta'] || ''}}</span>
            </div>
      
            <div class="col-md-4">
              <label for="email">Inaltime</label>
              <span>{{profil['inaltime'] || ''}}</span>
            </div>
      
            <div class="col-md-4">
              <label for="email">Culoare par</label>
              <span>{{profil['par'] || ''}}</span>
            </div>
      
            <div class="col-md-4">
              <label for="email">Culoare ochi</label>
              <span>{{profil['ochi'] || ''}}</span>
            </div>
      
            <div class="col-md-4">
              <label for="email">Cea mai buna parte a mea</label>
              <span>{{profil['parte'] || ''}}</span>
            </div>
  
        <div class="col-md-4">
          <label for="email">Copii</label>
          <span>{{profil['copii'] || ''}}</span>
        </div>
  
        <div class="col-md-4">
          <label for="email">Unde locuiesc</label>
          <span>{{profil['undelocuiesc'] || ''}}</span>
        </div>
  
        <div class="col-md-4">
          <label for="email">Cu cine locuiesc</label>
          <span>{{profil['cucinelocuiesc'] || ''}}</span>
        </div>
  
        <div class="col-md-4">
          <label for="email">Masina</label>
          <span>{{profil['masina'] || ''}}</span>
        </div>
  
        <div class="col-md-4">
          <label for="email">Religie</label>
          <span>{{profil['religie'] || ''}}</span>
        </div>
  
        <div class="col-md-4">
          <label for="email">Fumator</label>
          <span>{{profil['fumator'] || ''}}</span>
        </div>
  
        <div class="col-md-4">
          <label for="email">Bauturi</label>
          <span>{{profil['bauturi'] || ''}}</span>
        </div>
  
        </div>


    </div>
  </div>