

<section class="section section-lg bg-default text-center profile-page" [ngStyle]="{padding:modal?'0px 0px':'0px 0px'}">
  <div class="container">

    <div class="checkout-panel">
      <div class="panel-body">

        <div class="row fields-img" style="display: none;">
          <div class="col-md-6">
            <!-- <div (paste)="pasteImage($event)"> -->
            <input #fileinput1 id="uploadControl1" class="file-upload" type="file" accept="image/*"
              (change)="selectFile($event, 'img1')" style="display: none;">
            <img *ngIf="!profil['img1'] || profil['img1'] == '' || profil['img1'] == undefined"
              src="assets/images/noimage.jpg" />
            <img *ngIf="profil['img1'] !== '' && profil['img1'] !== undefined" class="" src="{{profil['img1']}}"
              [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img1')?'3px solid blue':''}" />
          </div>
          <div class="col-md-6">
            <!-- <div (paste)="pasteImage($event)"> -->
            <input #fileinput2 id="uploadControl2" class="file-upload" type="file" accept="image/*"
              (change)="selectFile($event, 'img2')" style="display: none;">
            <img *ngIf="!profil['img2'] || profil['img2'] == '' || profil['img2'] == undefined"
              src="assets/images/noimage.jpg" />
            <img *ngIf="profil['img2'] !== '' && profil['img2'] !== undefined" class="" src="{{profil['img2']}}"
              [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img2')?'3px solid blue':''}" />
          </div>
          <div class="col-md-6">
            <!-- <div (paste)="pasteImage($event)"> -->
            <input #fileinput3 id="uploadControl3" class="file-upload" type="file" accept="image/*"
              (change)="selectFile($event, 'img3')" style="display: none;">
            <img *ngIf="!profil['img3'] || profil['img3'] == '' || profil['img3'] == undefined"
              src="assets/images/noimage.jpg" />
            <img *ngIf="profil['img3'] !== '' && profil['img3'] !== undefined" class="" src="{{profil['img3']}}"
              [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img3')?'3px solid blue':''}" />
          </div>
          <div class="col-md-6">
            <!-- <div (paste)="pasteImage($event)"> -->
            <input #fileinput4 id="uploadControl4" class="file-upload" type="file" accept="image/*"
              (change)="selectFile($event, 'img4')" style="display: none;">
            <img *ngIf="!profil['img4'] || profil['img4'] == '' || profil['img4'] == undefined"
              src="assets/images/noimage.jpg" />
            <img *ngIf="profil['img4'] !== '' && profil['img4'] !== undefined" class="" src="{{profil['img4']}}"
              [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img4')?'3px solid blue':''}" />
          </div>

          <div *ngIf="currentFileUpload" style="display: flex; justify-content: center; width: 100%;">
            <div class="progress" style="margin:0px; margin-top: 5px;width: 200px;">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{percentage}}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{width:percentage+'%'}">
                {{percentage}}%
              </div>
            </div>
          </div>

        </div>

        <div class="titlecat">General</div>
        <div class="row fields">

          <div class="col-md-12" style="color: cornflowerblue;">
            {{profil['nume']}}, {{profil['varsta']}}, {{profil['localitate']}}
          </div>


          <!-- <div class="col-md-3">
            <label for="fullname">Nume</label>
            <input type="text" id="name" class="readonly" name="name" placeholder="nume" [(ngModel)]="profil['nume']"
              [ngClass]="{'error': submitted && !profil['nume']}" />
          </div>

          <div class="col-md-2">
            <label>Varsta</label>
            <input type="number" class="readonly" placeholder="varsta" [(ngModel)]="profil['varsta']"
              [ngClass]="{'error': submitted && !profil['varsta']}" />
          </div>

          <div class="col-md-3">
            <label>Gen</label>
            <select placeholder="gen" class="readonly" [(ngModel)]="profil['gen']" [ngClass]="{'error': submitted && !profil['gen']}">
              <option value="">selecteaza</option>
              <option value="Femeie">Femeie</option>
              <option value="Barbat">Barbat</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Localitate</label>
            <select placeholder="judet" class="readonly" [(ngModel)]="profil['localitate']">
              <option value="">selecteaza</option>
              <option value="Alba">Alba</option>
              <option value="Arad">Arad</option>
              <option value="Arges">Arges</option>
              <option value="Bacau">Bacau</option>
              <option value="Bihor">Bihor</option>
              <option value="Bistrita-Nasaud">Bistrita-Nasaud</option>
              <option value="Botosani">Botosani</option>
              <option value="Braila">Braila</option>
              <option value="Brasov">Brasov</option>
              <option value="Bucuresti">Bucuresti</option>
              <option value="Buzau">Buzau</option>
              <option value="Calarasi">Calarasi</option>
              <option value="Caras-Severin">Caras-Severin</option>
              <option value="Cluj">Cluj</option>
              <option value="Constanta">Constanta</option>
              <option value="Covasna">Covasna</option>
              <option value="Dambovita">Dambovita</option>
              <option value="Dolj">Dolj</option>
              <option value="Galati">Galati</option>
              <option value="Giurgiu">Giurgiu</option>
              <option value="Gorj">Gorj</option>
              <option value="Harghita">Harghita</option>
              <option value="Hunedoara">Hunedoara</option>
              <option value="Ialomita">Ialomita</option>
              <option value="Iasi">Iasi</option>
              <option value="Ilfov">Ilfov</option>
              <option value="Maramures">Maramures</option>
              <option value="Mehedinti">Mehedinti</option>
              <option value="Mures">Mures</option>
              <option value="Neamt">Neamt</option>
              <option value="Olt">Olt</option>
              <option value="Prahova">Prahova</option>
              <option value="Salaj">Salaj</option>
              <option value="Satu-Mare">Satu-Mare</option>
              <option value="Sibiu">Sibiu</option>
              <option value="Suceava">Suceava</option>
              <option value="Teleorman">Teleorman</option>
              <option value="Timis">Timis</option>
              <option value="Tulcea">Tulcea</option>
              <option value="Valcea">Valcea</option>
              <option value="Vaslui">Vaslui</option>
              <option value="Vrancea">Vrancea</option>
            </select>
          </div> -->

          <div class="col-md-4">
            <label>Status relatie</label>
            <select [(ngModel)]="profil['relatie']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Sunt singur">Singur(a)</option>
              <option value="Am pe cineva/sunt aici pentru prieteni">Am pe cineva/sunt aici pentru prieteni</option>
              <option value="Casatorit/sunt aici pentru prieteni">Casatorit/sunt aici pentru prieteni</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Educatie</label>
            <select [(ngModel)]="profil['educatie']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Gimnaziu">Gimnaziu</option>
              <option value="Scoala de arte si meserii">Scoala de arte si meserii</option>
              <option value="Liceu">Liceu</option>
              <option value="Universitate">Universitate</option>
              <option value="Diploma postuniversitara">Diploma postuniversitara</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Munca</label>
            <select [(ngModel)]="profil['munca']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Student / Diploma postuniversitara"> Student / Diploma postuniversitara </option>
              <option value="Lucrez"> Lucrez </option>
              <option value="Caut un loc de munca"> Caut un loc de munca </option>
              <option value="Pensionar"> Pensionar </option>
              <option value="Altul"> Altul </option>
            </select>
          </div>

        </div>

        <div class="titlecat">Aspect</div>
        <div class="row fields">

          <div class="col-md-4">
            <label>Inaltime</label>
            <select [(ngModel)]="profil['inaltime']" class="readonly">
              <option value="">selecteaza</option>
              <option value="<130">&lt;130</option>
              <option value="130-140">130-140</option>
              <option value="140-150">140-150</option>
              <option value="150-160">150-160</option>
              <option value="160-170">160-170</option>
              <option value="170-180">170-180</option>
              <option value="180-190">180-190</option>
              <option value=">190-200">190-200</option>
              <option value="200-210">200-210</option>
              <option value="210-220">210-220</option>
              <option value=">230">&gt;230</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Culoare par</label>
            <select [(ngModel)]="profil['par']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Negru">Negru</option>
              <option value="Blond">Blond</option>
              <option value="Saten">Saten</option>
              <option value="Roscat">Roscat</option>
              <option value="Gri">Gri</option>
              <option value="Alb">Alb</option>
              <option value="Chel">Chel</option>
              <option value="Vopsit">Vopsit</option>
              <option value="Alta">Alta</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Culoare ochi</label>
            <select [(ngModel)]="profil['ochi']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Caprui">Caprui</option>
              <option value="Gri">Gri</option>
              <option value="Verzi">Verzi</option>
              <option value="Albastri">Albastri</option>
              <option value="Maro deschis">Maro deschis</option>
              <option value="Alta">Alta</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Silueta</label>
            <select [(ngModel)]="profil['silueta']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Slab">Slab</option>
              <option value="Sportiv">Sportiv</option>
              <option value="Cu forme">Cu forme</option>
              <option value="Rotund"> Rotund</option>
              <option value="Supermodel">Supermodel</option>
              <option value="Atlet olimpic">Atlet olimpic</option>
              <option value="Sigur iti va placea cum arat">Sigur iti va placea cum arat</option>
              <option value="Iti spun mai tarziu">Iti spun mai tarziu</option>
              <option value="Te las sa te convingi singur">Te las sa te convingi singur</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Cea mai buna parte a mea</label>
            <select [(ngModel)]="profil['parte']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Fata">Fata</option>
              <option value="Ochi">Ochi</option>
              <option value="Buze">Buze</option>
              <option value="Gura">Gura</option>
              <option value="Zambet">Zambet</option>
              <option value="Nas">Nas</option>
              <option value="Chel">Chel</option>
              <option value="Par">Par</option>
              <option value="Gat">Gat</option>
              <option value="Piept">Piept</option>
              <option value="Burta">Burta</option>
              <option value="Buric">Buric</option>
              <option value="Maini">Maini </option>
              <option value="Picioare">Picioare</option>
              <option value="Fund">Fund</option>
              <option value="Picioare">Picioare</option>
              <option value="Muschi">Muschi</option>
              <option value="Creier">Creier</option>
              <option value="Spatele">Spatele</option>
              <option value="Bratele">Bratele</option>
            </select>
          </div>

        </div>

        <div class="titlecat">Stil</div>
        <div class="row fields">

          <div class="col-md-4">
            <label>Copii</label>
            <select [(ngModel)]="profil['copii']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Nu, niciodata">Nu, niciodata</option>
              <option value="Intr-o zi, poate'">Intr-o zi, poate</option>
              <option value="Cu cat mai devreme">Cu cat mai devreme</option>
              <option value="In asteptare">In asteptare</option>
              <option value="Am deja copii">Am deja copii</option>
              <option value="Am copii si nu imi doresc altii">Am copii si nu imi doresc altii</option>
            </select>
          </div>

          <!-- <div class="col-md-4">
            <label>Unde locuiesc</label>
            <select [(ngModel)]="profil['undelocuiesc']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Studio">Studio</option>
              <option value="Apartament">Apartament</option>
              <option value="Casa">Casa </option>
              <option value="Vila">Vila</option>
              <option value="Ferma">Ferma</option>
              <option value="Caravana">Caravana</option>
              <option value="Barca"> Barca</option>
              <option value="In altceva">In altceva</option>
            </select>
          </div> -->

          <div class="col-md-4">
            <label>Cu cine locuiesc</label>
            <select [(ngModel)]="profil['cucinelocuiesc']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Singur">Singur(a)</option>
              <option value="Cu parintii">Cu parintii</option>
              <option value="Cu prietenii">Cu prietenii</option>
              <option value="Cu partenera">Cu partenera</option>
              <option value="Cu copii">Cu copii</option>
              <option value="Altii">Altii</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Masina</label>
            <select [(ngModel)]="profil['masina']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Nu am una">Nu am</option>
              <option value="Am masina mea">Am masina mea</option>
              <option value="Conduc masina parintilor">Conduc masina parintilor </option>
              <option value="Nu am nici macar carnet">Nu am nici macar carnet</option>
              <option value="Am o masina a firmei">Am o masina a firmei</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Religie</label>
            <select [(ngModel)]="profil['religie']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Ateist">Ateist</option>
              <option value="Budist">Budist</option>
              <option value="Catolic">Catolic</option>
              <option value="Hindus">Hindus</option>
              <option value="Evreu">Evreu</option>
              <option value="Musulman">Musulman</option>
              <option value="Ortodox">Ortodox</option>
              <option value="Protestant">Protestant</option>
              <option value="Alta">Alta</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Fumator</label>
            <select [(ngModel)]="profil['fumator']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Nu fumez">Nu fumez</option>
              <option value="Nu imi plac fumatorii">Nu imi plac fumatorii</option>
              <option value="Fumez"> Fumez</option>
              <option value="Fumez la ocazii">Fumez la ocazii</option>
              <option value="Fumator Inrait">Fumator Inrait</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Bauturi</label>
            <select [(ngModel)]="profil['bauturi']" class="readonly">
              <option value="">selecteaza</option>
              <option value="Nu beau">Nu beau</option>
              <option value="Nu imi plac bautorii">Nu imi plac bautorii</option>
              <option value="Beau"> Beau</option>
              <option value="Beau la ocazii">Beau la ocazii</option>
              <option value="Alcoolic">Alcoolic</option>
            </select>
          </div>

        </div>

        <div *ngIf="!short && false" class="progress-bar-pay mbdiv">
          <div class="step" [ngClass]="{'active':step_current==1, 'complete':step_current>1}" (click)="step_current=1">
          </div>
          <div class="step" [ngClass]="{'active':step_current==2, 'complete':step_current>2}" (click)="step_current=2">
          </div>
          <div class="step" [ngClass]="{'active':step_current==3, 'complete':step_current>3}" (click)="step_current=3">
          </div>
          <div class="step" [ngClass]="{'active':step_current==4, 'complete':step_current>4}" (click)="step_current=4">
          </div>
        </div>


        <div *ngIf="step_current == 1 && false" class="tabpay">

          <!-- <h4 class="mb-10">Billing details</h4> -->

          <div class="row fields">

            <div class="col-md-3">
              <label for="fullname">Nume</label>
              <input type="text" id="name" class="readonly" name="name" placeholder="nume" [(ngModel)]="profil['nume']"
                [ngClass]="{'error': submitted && !profil['nume']}" />
            </div>

            <div class="col-md-3">
              <label>Varsta</label>
              <input type="number" class="readonly" placeholder="varsta" [(ngModel)]="profil['varsta']"
                [ngClass]="{'error': submitted && !profil['varsta']}" />
            </div>

            <div class="col-md-3">
              <label>Gen</label>
              <select placeholder="gen" class="readonly" [(ngModel)]="profil['gen']" [ngClass]="{'error': submitted && !profil['gen']}">
                <option value="">selecteaza</option>
                <option value="Femeie">Femeie</option>
                <option value="Barbat">Barbat</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Localitate</label>
              <select placeholder="judet" class="readonly" [(ngModel)]="profil['localitate']">
                <option value="">selecteaza</option>
                <option value="Alba">Alba</option>
                <option value="Arad">Arad</option>
                <option value="Arges">Arges</option>
                <option value="Bacau">Bacau</option>
                <option value="Bihor">Bihor</option>
                <option value="Bistrita-Nasaud">Bistrita-Nasaud</option>
                <option value="Botosani">Botosani</option>
                <option value="Braila">Braila</option>
                <option value="Brasov">Brasov</option>
                <option value="Bucuresti">Bucuresti</option>
                <option value="Buzau">Buzau</option>
                <option value="Calarasi">Calarasi</option>
                <option value="Caras-Severin">Caras-Severin</option>
                <option value="Cluj">Cluj</option>
                <option value="Constanta">Constanta</option>
                <option value="Covasna">Covasna</option>
                <option value="Dambovita">Dambovita</option>
                <option value="Dolj">Dolj</option>
                <option value="Galati">Galati</option>
                <option value="Giurgiu">Giurgiu</option>
                <option value="Gorj">Gorj</option>
                <option value="Harghita">Harghita</option>
                <option value="Hunedoara">Hunedoara</option>
                <option value="Ialomita">Ialomita</option>
                <option value="Iasi">Iasi</option>
                <option value="Ilfov">Ilfov</option>
                <option value="Maramures">Maramures</option>
                <option value="Mehedinti">Mehedinti</option>
                <option value="Mures">Mures</option>
                <option value="Neamt">Neamt</option>
                <option value="Olt">Olt</option>
                <option value="Prahova">Prahova</option>
                <option value="Salaj">Salaj</option>
                <option value="Satu-Mare">Satu-Mare</option>
                <option value="Sibiu">Sibiu</option>
                <option value="Suceava">Suceava</option>
                <option value="Teleorman">Teleorman</option>
                <option value="Timis">Timis</option>
                <option value="Tulcea">Tulcea</option>
                <option value="Valcea">Valcea</option>
                <option value="Vaslui">Vaslui</option>
                <option value="Vrancea">Vrancea</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Status relatie</label>
              <select [(ngModel)]="profil['relatie']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Sunt singur(a)">Sunt singur(a)</option>
                <option value="Am pe cineva/sunt aici pentru prieteni">Am pe cineva/sunt aici pentru prieteni</option>
                <option value="Casatorit/sunt aici pentru prieteni">Casatorit/sunt aici pentru prieteni</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Educatie</label>
              <select [(ngModel)]="profil['educatie']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Gimnaziu">Gimnaziu</option>
                <option value="Scoala de arte si meserii">Scoala de arte si meserii</option>
                <option value="Liceu">Liceu</option>
                <option value="Universitate">Universitate</option>
                <option value="Diploma postuniversitara">Diploma postuniversitara</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Munca</label>
              <select [(ngModel)]="profil['munca']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Student / Diploma postuniversitara"> Student / Diploma postuniversitara </option>
                <option value="Lucrez"> Lucrez </option>
                <option value="Caut un loc de munca"> Caut un loc de munca </option>
                <option value="Pensionar"> Pensionar </option>
                <option value="Altul"> Altul </option>
              </select>
            </div>

          </div>

        </div>


        <div *ngIf="step_current == 2 && false" class="tabpay">

          <div class="row fields">

            <div class="col-md-3">
              <label>Silueta</label>
              <select [(ngModel)]="profil['silueta']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Slab">Slab</option>
                <option value="Sportiv">Sportiv</option>
                <option value="Cu forme">Cu forme</option>
                <option value="Rotund"> Rotund</option>
                <option value="Supermodel">Supermodel</option>
                <option value="Atlet olimpic">Atlet olimpic</option>
                <option value="Sigur iti va placea cum arat">Sigur iti va placea cum arat</option>
                <option value="Iti spun mai tarziu">Iti spun mai tarziu</option>
                <option value="Te las sa te convingi singur">Te las sa te convingi singur</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Inaltime</label>
              <select [(ngModel)]="profil['inaltime']" class="readonly">
                <option value="">selecteaza</option>
                <option value="<130">&lt;130</option>
                <option value="130-140">130-140</option>
                <option value="140-150">140-150</option>
                <option value="150-160">150-160</option>
                <option value="160-170">160-170</option>
                <option value="170-180">170-180</option>
                <option value="180-190">180-190</option>
                <option value=">190-200">190-200</option>
                <option value="200-210">200-210</option>
                <option value="210-220">210-220</option>
                <option value=">230">&gt;230</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Culoare par</label>
              <select [(ngModel)]="profil['par']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Negru">Negru</option>
                <option value="Blond">Blond</option>
                <option value="Saten">Saten</option>
                <option value="Roscat">Roscat</option>
                <option value="Gri">Gri</option>
                <option value="Alb">Alb</option>
                <option value="Chel">Chel</option>
                <option value="Vopsit">Vopsit</option>
                <option value="Alta">Alta</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Culoare ochi</label>
              <select [(ngModel)]="profil['ochi']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Caprui">Caprui</option>
                <option value="Gri">Gri</option>
                <option value="Verzi">Verzi</option>
                <option value="Albastri">Albastri</option>
                <option value="Maro deschis">Maro deschis</option>
                <option value="Alta">Alta</option>
              </select>
            </div>

            <div class="col-md-3">
              <label>Cea mai buna parte a mea</label>
              <select [(ngModel)]="profil['parte']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Fata">Fata</option>
                <option value="Ochi">Ochi</option>
                <option value="Buze">Buze</option>
                <option value="Gura">Gura</option>
                <option value="Zambet">Zambet</option>
                <option value="Nas">Nas</option>
                <option value="Chel">Chel</option>
                <option value="Par">Par</option>
                <option value="Gat">Gat</option>
                <option value="Piept">Piept</option>
                <option value="Burta">Burta</option>
                <option value="Buric">Buric</option>
                <option value="Maini">Maini </option>
                <option value="Picioare">Picioare</option>
                <option value="Fund">Fund</option>
                <option value="Picioare">Picioare</option>
                <option value="Muschi">Muschi</option>
                <option value="Creier">Creier</option>
                <option value="Spatele">Spatele</option>
                <option value="Bratele">Bratele</option>
              </select>
            </div>

          </div>

        </div>

        <div *ngIf="step_current == 3 && false" class="clearfix tabpay">

          <div class="row fields">

            <div class="col-md-4">
              <label>Copii</label>
              <select [(ngModel)]="profil['copii']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Nu, niciodata">Nu, niciodata</option>
                <option value="Intr-o zi, poate'">Intr-o zi, poate</option>
                <option value="Cu cat mai devreme">Cu cat mai devreme</option>
                <option value="In asteptare">In asteptare</option>
                <option value="Am deja copii">Am deja copii</option>
                <option value="Am copii si nu imi doresc altii">Am copii si nu imi doresc altii</option>
              </select>
            </div>

            <!-- <div class="col-md-3">
              <label>Unde locuiesc</label>
              <select [(ngModel)]="profil['undelocuiesc']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Studio">Studio</option>
                <option value="Apartament">Apartament</option>
                <option value="Casa">Casa </option>
                <option value="Vila">Vila</option>
                <option value="Ferma">Ferma</option>
                <option value="Caravana">Caravana</option>
                <option value="Barca"> Barca</option>
                <option value="In altceva">In altceva</option>
              </select>
            </div> -->

            <div class="col-md-4">
              <label>Cu cine locuiesc</label>
              <select [(ngModel)]="profil['cucinelocuiesc']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Singur">Singur</option>
                <option value="Cu parintii">Cu parintii</option>
                <option value="Cu prietenii">Cu prietenii</option>
                <option value="Cu partenera">Cu partenera</option>
                <option value="Cu copii">Cu copii</option>
                <option value="Altii">Altii</option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Masina</label>
              <select [(ngModel)]="profil['masina']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Nu am una">Nu am</option>
                <option value="Am masina mea">Am masina mea</option>
                <option value="Conduc masina parintilor">Conduc masina parintilor </option>
                <option value="Nu am nici macar carnet">Nu am nici macar carnet</option>
                <option value="Am o masina a firmei">Am o masina a firmei</option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Religie</label>
              <select [(ngModel)]="profil['religie']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Ateist">Ateist</option>
                <option value="Budist">Budist</option>
                <option value="Catolic">Catolic</option>
                <option value="Hindus">Hindus</option>
                <option value="Evreu">Evreu</option>
                <option value="Musulman">Musulman</option>
                <option value="Ortodox">Ortodox</option>
                <option value="Protestant">Protestant</option>
                <option value="Alta">Alta</option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Fumator</label>
              <select [(ngModel)]="profil['fumator']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Nu fumez">Nu fumez</option>
                <option value="Nu imi plac fumatorii">Nu imi plac fumatorii</option>
                <option value="Fumez"> Fumez</option>
                <option value="Fumez la ocazii">Fumez la ocazii</option>
                <option value="Fumator Inrait">Fumator Inrait</option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Bauturi</label>
              <select [(ngModel)]="profil['bauturi']" class="readonly">
                <option value="">selecteaza</option>
                <option value="Nu beau">Nu beau</option>
                <option value="Nu imi plac bautorii">Nu imi plac bautorii</option>
                <option value="Beau"> Beau</option>
                <option value="Beau la ocazii">Beau la ocazii</option>
                <option value="Alcoolic">Alcoolic</option>
              </select>
            </div>

          </div>

        </div>

        <div *ngIf="step_current == 4 && false" class="clearfix tabpay images">
          <div class="row fields-img">
            <div class="col-md-6">
              <!-- <div (paste)="pasteImage($event)"> -->
              <input #fileinput1 id="uploadControl1" class="file-upload" type="file" accept="image/*"
                (change)="selectFile($event, 'img1')" style="display: none;">
              <img *ngIf="!profil['img1'] || profil['img1'] == '' || profil['img1'] == undefined"
                src="assets/images/noimage.jpg" />
              <img *ngIf="profil['img1'] !== '' && profil['img1'] !== undefined" class="" src="{{profil['img1']}}"
                [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img1')?'3px solid blue':''}" />
            </div>
            <div class="col-md-6">
              <!-- <div (paste)="pasteImage($event)"> -->
              <input #fileinput2 id="uploadControl2" class="file-upload" type="file" accept="image/*"
                (change)="selectFile($event, 'img2')" style="display: none;">
              <img *ngIf="!profil['img2'] || profil['img2'] == '' || profil['img2'] == undefined"
                src="assets/images/noimage.jpg" />
              <img *ngIf="profil['img2'] !== '' && profil['img2'] !== undefined" class="" src="{{profil['img2']}}"
                [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img2')?'3px solid blue':''}" />
            </div>
            <div class="col-md-6">
              <!-- <div (paste)="pasteImage($event)"> -->
              <input #fileinput3 id="uploadControl3" class="file-upload" type="file" accept="image/*"
                (change)="selectFile($event, 'img3')" style="display: none;">
              <img *ngIf="!profil['img3'] || profil['img3'] == '' || profil['img3'] == undefined"
                src="assets/images/noimage.jpg" />
              <img *ngIf="profil['img3'] !== '' && profil['img3'] !== undefined" class="" src="{{profil['img3']}}"
                [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img3')?'3px solid blue':''}" />
            </div>
            <div class="col-md-6">
              <!-- <div (paste)="pasteImage($event)"> -->
              <input #fileinput4 id="uploadControl4" class="file-upload" type="file" accept="image/*"
                (change)="selectFile($event, 'img4')" style="display: none;">
              <img *ngIf="!profil['img4'] || profil['img4'] == '' || profil['img4'] == undefined"
                src="assets/images/noimage.jpg" />
              <img *ngIf="profil['img4'] !== '' && profil['img4'] !== undefined" class="" src="{{profil['img4']}}"
                [ngStyle]="{border: (profil['profilimg'] && profil['profilimg']=='img4')?'3px solid blue':''}" />
            </div>

            <div *ngIf="currentFileUpload" style="display: flex; justify-content: center; width: 100%;">
              <div class="progress" style="margin:0px; margin-top: 5px;width: 200px;">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{percentage}}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{width:percentage+'%'}">
                  {{percentage}}%
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- <div *ngIf="step_current == 1" class="panel-footer mbdiv" style="display: flex;justify-content: center;">
    <button class="btn next-btn" (click)="next()">Next</button>
  </div>
  <div *ngIf="step_current == 2" class="panel-footer mbdiv">
    <button class="btn back-btn" (click)="back()">Back</button>
    <button class="btn next-btn" (click)="next()">Next</button>
  </div>
  <div *ngIf="step_current == 3" class="panel-footer mbdiv" style="display: flex;justify-content: center;">
    <button class="btn back-btn" (click)="back()">Back</button>
  </div> -->

    </div>

  </div>

</section>