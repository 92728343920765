import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service'
declare let $: any;

@Component({
  selector: 'app-profileuser',
  templateUrl: './profileuser.component.html',
  styleUrls: ['./profileuser.component.scss']
})

export class ProfileUserComponent implements OnInit, AfterViewInit {

  currentUser;
  submitted;

  profil={};user;
  step_current_user=1;

  constructor(
    private authService: AuthService, 
    private afAuth: AngularFireAuth, 
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService) { }

  ngOnInit(): void {

    let self = this;

    this.afAuth.authState.subscribe((data) => {
      this.currentUser = data;

      
      this.dataService.getItemByID("users",  this.currentUser['uid']).subscribe((data) => {

        this.user = data;

        if (data && !data['profile'])
        data['profile'] = {};

        self.profil = data['profile'];
      });
    });

  }

  ngAfterViewInit() {
  }

  back(){
    if (!this.step_current_user)
    return;

    if (this.step_current_user==1)
    return;

    if (this.step_current_user==3){

    }

    this.step_current_user = this.step_current_user -1;
  }

  next(){

    this.submitted = true;

    if (!this.step_current_user && this.step_current_user != 0)
    return;

    //billing details
    if (this.step_current_user === 1){
    }

    if (this.step_current_user === 2){
 
    }

    if (this.step_current_user === 2)
    return;

    this.step_current_user = this.step_current_user+1;
  }
  
  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

}
