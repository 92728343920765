import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service'
import { UUID } from 'angular2-uuid';
import { NgNotFoundTemplateDirective } from '@ng-select/ng-select/lib/ng-templates.directive';

declare let $: any;

@Component({
  selector: 'app-eventadmin',
  templateUrl: './eventadmin.component.html',
  styleUrls: ['./eventadmin.component.scss']
})

export class EventAdminComponent implements OnInit, AfterViewInit {

  list; events; eventselected;
  currentUser;
  submittedcom;
  bWantEvent;

  loaded;
  msgcopy; genLinkID;

  currentprofil; currentimg;

  clicklike;

  gen; relatie; educatie; localitate; afiseazatot;

  constructor(
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private dataService: DataService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    let self = this;

    // this.addscript(this.elementRef.nativeElement, "https://cdnjs.cloudflare.com/ajax/libs/ekko-lightbox/5.3.0/ekko-lightbox.min.js", "ekkolightboxminjs");

    this.afAuth.authState.subscribe((data) => {
      this.currentUser = data;

      let sub1 = this.dataService.getItemByID("users", this.currentUser.uid).subscribe((datau) => {
        sub1.unsubscribe();

        if(datau && datau['filters']){
          if (datau['filters'] && datau['filters']['gen'] && datau['filters']['gen'] != '')
          this.gen = datau['filters']['gen'];
          if (datau['filters'] && datau['filters']['relatie'] && datau['filters']['relatie'] != '')
          this.relatie = datau['filters']['relatie'];
          if (datau['filters'] && datau['filters']['educatie'] && datau['filters']['educatie'] != '')
          this.educatie = datau['filters']['educatie'];
          if (datau['filters'] && datau['filters']['localitate'] && datau['filters']['localitate'] != '')
          this.localitate = datau['filters']['localitate'];
          if (datau['filters'] && datau['filters']['afiseazatot'])
          this.afiseazatot = datau['filters']['afiseazatot'];
        }
      });

      let sub3 = this.dataService.getList("events").subscribe((data) => {
        sub3.unsubscribe();

        if (data) {
          this.events = data.filter(s => {
            return s['userid'] == this.currentUser.uid;
          })
        }

        this.loaded = true;
      })

    });

  }

  ngAfterViewInit() {
    $('.headerindex').css('display', 'none');
    // $('.footerindex').css('display', 'none');
  }

  logout() {
    return this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

  copylink() {
    let self = this;
    var copyText = <HTMLInputElement>document.getElementById("copylink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    this.msgcopy = "Copiat!";
    setTimeout(() => {
      self.msgcopy = '';
    }, 1000);
  }

  async setlike(item) {
    this.clicklike = true;
    let item_ = { id: this.currentUser.uid }
    this.dataService.saveSubItem('users_event', item['id'], 'likes', item_)
    item['like'] = true;

    await this.dataService.getSubListByID("users_event", this.currentUser.uid, "likes", item['id']).subscribe((data4) => {
      if (data4) {
        item['success'] = true;
        this.dataService.saveItem('users_event', { id: item['id'], success: true })
        this.dataService.saveItem('users_event', { id: this.currentUser.uid, success: true })
      }
      else {
        item['success'] = false;
        this.dataService.saveItem('users_event', { id: item['id'], success: false })
        this.dataService.saveItem('users_event', { id: this.currentUser.uid, success: false })
      }
    })
  }
  setunlike(item) {
    this.clicklike = true;
    this.dataService.deleteSubItem('users_event', item['id'], 'likes', this.currentUser.uid)
    item['like'] = false;
    item['success'] = false;
    this.dataService.saveItem('users_event', { id: item['id'], success: false })
    this.dataService.saveItem('users_event', { id: this.currentUser.uid, success: false })
  }

  async sethide(item) {
    this.clicklike = true;
    let item_ = { id: item['id'] }

    await this.dataService.getSubListByID("users_event", this.currentUser.uid, "hide", item['id']).subscribe((data4) => {
      if (!data4) {
        this.dataService.saveSubItem('users_event', this.currentUser.uid, 'hide', item_)
        item['hide'] = true;
      }
    })
  }
  setunhide(item) {
    this.clicklike = true;
    this.dataService.deleteSubItem('users_event', this.currentUser.uid, 'hide', item['id'])
    item['hide'] = false;
  }

  setProfile(item) {
    this.clicklike = true;
    this.currentprofil = item;
    $("#profileview").modal();
  }

  viewImages(item){
    if (this.clicklike) {
      this.clicklike = false;
      return;
    }

    this.clicklike = false;
    this.currentprofil = item;
    this.currentimg = "img";
    $("#imageview").modal();
  }

  previmg(){
    let nr = parseInt(this.currentimg.replace('img',''));
    if (!nr)
    nr = 0;

    if (nr <= 0){
      if(this.currentprofil['profile']['img1'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
      else if(this.currentprofil['profile']['img2'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
      else if(this.currentprofil['profile']['img3'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";
      else if(this.currentprofil['profile']['img4'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";
    }

    if (nr == 1 && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
    else if (nr == 1 && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";
    else if (nr == 1 && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";

      if (nr == 2 && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
    else if (nr == 2 && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
    else if (nr == 2 && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";

      if (nr == 3 && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";
    else if (nr == 3 && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
    else if (nr == 3 && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";

      if (nr >=4 && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";
    else if (nr >= 4 && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";
    else if (nr >= 4 && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
  }
  nextimg(){
    let nr = parseInt(this.currentimg.replace('img',''));
    if (!nr)
    nr = 0;

    if (nr <= 0){
      if(this.currentprofil['profile']['img1'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";
      else if(this.currentprofil['profile']['img2'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";
      else if(this.currentprofil['profile']['img3'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
      else if(this.currentprofil['profile']['img4'] == this.currentprofil['profile']['img'] && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
    }

    if (nr >= 4 && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
    else if (nr >= 4 && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";
    else if (nr >= 4 && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";

      if (nr == 3 && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
    else if (nr == 3 && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";
    else if (nr == 3 && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";

      if (nr == 2 && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";
    else if (nr == 2 && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
    else if (nr == 2 && this.currentprofil['profile']['img1'])
      this.currentimg = "img1";

      if (nr == 1 && this.currentprofil['profile']['img2'])
      this.currentimg = "img2";
    else if (nr == 1 && this.currentprofil['profile']['img3'])
      this.currentimg = "img3";
    else if (nr == 1 && this.currentprofil['profile']['img4'])
      this.currentimg = "img4";
  }

  getFirstUpper(str) {
    if (str && str != '')
      return str.charAt(0).toUpperCase() + str.slice(1);
    else
      return '';
  }

  getlist(){
    let result = [], found0,found1,found2,found21,found3;

    this.list.forEach(element => {
      found0=false;found1=false;found2=false;found21=false;found3=false;

      if (this.gen)
      if (element['profile'] && element['profile']['gen'] == this.gen){
        found0=true;
      }

      if (this.relatie)
      if (element['profile'] && element['profile']['relatie'] == this.relatie){
        found1=true;
      }

      if (this.educatie)
      if (element['profile'] && element['profile']['educatie'] == this.educatie){
        found2=true;
      }

      if (this.localitate)
      if (element['profile'] && element['profile']['localitate'] == this.localitate){
        found21=true;
      }

      if (this.afiseazatot || !element['hide']){
        found3=true;
      }

      if ((!this.gen || found0) && (!this.relatie || found1) && 
      (!this.educatie || found2) && (!this.localitate || found21) && found3)
      result.push(element);

    });

    return result;
  }

  savefilters(){
    let item_ = { id: this.currentUser.uid, filters: {gen: this.gen?this.gen:'', 
    relatie: this.relatie?this.relatie:'', educatie: this.educatie?this.educatie:'', localitate: this.localitate?this.localitate:'', 
    afiseazatot:this.afiseazatot?this.afiseazatot:false} }
    this.dataService.saveItem("users", item_)
  }

  deletefilters(){
    delete this.gen;
    delete this.relatie;
    delete this.educatie;
    delete this.localitate;
    delete this.afiseazatot;
    this.dataService.saveItem("users", { id: this.currentUser.uid, filters: {}})
  }

  nrimages(){
  let nr = 0;

  if(this.currentprofil['profile']['img1'])
  nr = nr + 1;
  if(this.currentprofil['profile']['img2'])
  nr = nr + 1;
  if(this.currentprofil['profile']['img3'])
  nr = nr + 1;
  if(this.currentprofil['profile']['img4'])
  nr = nr + 1;

  return nr;
  }

  addscript(_elem, _file, _class, _class_general = ''){

    let content = document.getElementsByTagName('body')[0];
    let arr = document.getElementsByClassName(_class);
    for (let i = 0; i < arr.length; i++) {
    arr[i].remove();
    i = i-1;
    }

    var s = document.createElement("script");
    s.type = "text/javascript"; //"text/javascript";

    s.classList.add(_class);
    if (_class_general != '')
    s.classList.add(_class_general);

    s.src = _file;
    _elem.appendChild(s);

    return s;
}

async eventselect(event){
  delete this.eventselected;
  
  this.events.forEach(element => {
    if (element.id == event.target.value)
    this.eventselected = element;
  });

  await this.dataService.getSubList("events", this.eventselected['id'], "users").subscribe(async (dataux) => {
    let self = this;
    
    if (dataux){
      this.bWantEvent = dataux;

      self.list = dataux;
      self.list = self.list.filter(s => {
        return s['userid'] != this.currentUser.uid && !s['cancel'];
      })

      this.dataService.getList("users").subscribe((data2) => {
        if (data2 && data2.length > 0) {
          self.list.forEach(async element => {

            if (!element['profile'])
              element['profile'] = {}

            // await this.dataService.getSubListByID("users_event", element['id'], "likes", this.currentUser.uid).subscribe(async (data3) => {
            //   if (data3)
            //     element['like'] = true;

            //   if (element['like']) {
            //     await this.dataService.getSubListByID("users_event", this.currentUser.uid, "likes", element['id']).subscribe((data4) => {
            //       if (data4)
            //         element['success'] = true;
            //     })
            //   }
            // })

            // await this.dataService.getSubListByID("users_event", this.currentUser.uid, "hide", element['id']).subscribe(async (data3) => {
            //   if (data3)
            //     element['hide'] = true;
            // })

            data2.forEach(element2 => {
              if (element2['uid'] == element['id']) {

                if (!element2['profile'])
                  element2['profile'] = {}

                if (element2['profile']['profilimg'])
                  element2['profile']['img'] = element2['profile'][element2['profile']['profilimg']]
                else if (element2['profile']['img1'])
                  element2['profile']['img'] = element2['profile']['img1']
                else if (element2['profile']['img2'])
                  element2['profile']['img'] = element2['profile']['img2']
                else if (element2['profile']['img3'])
                  element2['profile']['img'] = element2['profile']['img3']
                else if (element2['profile']['img4'])
                  element2['profile']['img'] = element2['profile']['img4']

                element['profile'] = element2['profile']
                element['email'] = element2['email']
                element['uid'] = element2['uid']
              }
            });
          });

          self.list = self.list.filter(s => {
            return s['profile'];
          })

        }
      })  
    }
    else
    this.bWantEvent = [];
  })

}

setchat(item){
  this.clicklike = true;

  this.router.navigate(['/chat/'+item.uid + '/1']);
}

}
