import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { map, switchMap, take, first } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class DataService {
    collectionRef = new BehaviorSubject<AngularFirestoreCollection<any>>(null);
    currentUser;
    path = '';

    constructor(private angularFireAuth: AngularFireAuth, private afStore: AngularFirestore) {
        this.angularFireAuth.authState.subscribe((user) => {
            if (user) {
                this.currentUser = user;
                this.path = `companies/${this.currentUser.uid}/expert-lists`;
                this.collectionRef.next(this.afStore.collection(this.path));
            }
        });
    }

    getList(entity): Observable<any[]>{
        const companyRef = this.afStore.collection(entity);
        return companyRef.valueChanges();
      }
    
      getItemByID(entity, id){
        const companyRef = this.afStore.collection(entity).doc(id);
        return companyRef.valueChanges();
      }

      getSubList(entity: string, id: string, subentity: string): Observable<any[]> {
        const ref = this.afStore.collection(`${entity}/${id}/${subentity}`)
        return ref.valueChanges()
      }
      getSubListByID(entity: string, id: string, subentity: string, subid: string){
        const ref = this.afStore.collection(entity).doc(id).collection(subentity).doc(subid)
        return ref.valueChanges()
      }
    
      saveItem(entity, item, id='id'){

        if (!item[id]) 
          item[id] = this.guid();

        const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${item[id]}`);
        return itemRef.set(item, {merge: true});
      }

      saveSubItem(entity: string, id: string, subentity: string, item: any) {
        if (!item.id) item.id = this.guid();
    
        const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${id}/${subentity}/${item.id}`)
        return itemRef.set(item, { merge: true })
      }
      async deleteItem(entity, uid) {
        // const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${uid}`);
        // await itemRef.delete();
        return this.afStore.collection(entity).doc(uid).delete();
      }

      async deleteSubItem(entity, id, subentity, subid) {
        return this.afStore.doc(`${entity}/${id}/${subentity}/${subid}`).delete();
      }

    get timestamp() {
        return new Date();
    }

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    guid() {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
            this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }
}
